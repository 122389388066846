import React, { useEffect, useRef } from "react";
import { Stack } from "@mui/material";

import { AppLayout } from "../../components/layouts/appLayout";
import { useTodayPageQuery } from "../../generated/graphql";
import { AppErrorPage } from "../../components/appErrorPage";
import { PlanPageDashboard } from "./today/planPageDashboard";
import { TimerState } from "./today/utils/timerState";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  clearTodayPageState,
  initializeTodayPage,
  selectTodayPageState,
  setAddTaskInFlight,
  setAutoAddModeGroup,
  setEditingInProgress,
  setIsEditingTaskId,
  setTimerRoleId,
  setTimerState,
  setTimerType,
  timerEnd,
  toggleRoleIdSelected,
} from "./todayPageSlice";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { AppSnackbar } from "../../components/appSnackbar";
import { DropResult } from "react-beautiful-dnd";
import { useNavigate, useParams } from "react-router-dom";
import {
  getPlanToolbarCookies,
  getTimerCookies,
  setPlanToolbarCookies,
  setTimerCookies,
} from "./today/utils/todayPageCookies";
import { timerEffectDispatch } from "./today/utils/timerEffect";

import { TimerType, timeForTimerType } from "./today/utils/timerType";
import { EndTimerPromptDialog } from "./today/endTimerPromptDialog";
import { PlanRole, RoleBlockType, PlanTaskGroup, PlanProject, nullPlanProject } from "../../util/modelTypes";
import { PlannedTaskGroups } from "./today/plannedTaskGroups";
import { GuideRail, GuideRailAPISetters } from "./today/guideRail";
import {
  totalTimeBlocksCompleted,
  groupThatWillBeGrouped,
  colorForRoleInState,
  onCanBeGrouped,
  firstGroupWithIncompleteTasksInTimerRole,
  blocksLeftForRole,
  blocksLeftForRoleInPlan,
  blocksPastForRole,
  colorForRole,
  shouldBlockPlanUIFromState,
  generateProjectsList,
  grayTimeBlocksCompleted,
} from "./today/todayPageStateUtils";
import { useVerticalScrollTracker } from "../../util/scrollTracker";
import { compareDates, formatDateSimple } from "../../util/dateUtils";
import { selectAppState, setAppBarHidden, setBacklogDrawerIsOpen, setSidebarDrawerIsOpen } from "../appSlice";
import Cookies from "js-cookie";
import { PlanTasksToolbar } from "./today/planTasksToolbar";
import { selectBacklogState } from "./backlogSlice";
import { apiUnplanTask } from "./today/api/today.api.plan.backlog";
import {
  apiMakeTaskGroupIntoRoutine,
  apiPlanRoutine,
  apiUpdateRoutineFromTaskGroup,
} from "./today/api/today.api.routines";
import { selectRoutinesState } from "./routinesSlice";
import { PromptForTextDialog } from "../../components/dialogs/promptForTextDialog";
import { PromptForDateDialog } from "../../components/dialogs/promptForDateDialog";
import { PromptForProjectDialog } from "../../components/dialogs/promptForProjectDialog";
import { EditProjectDialog } from "../../components/dialogs/editProjectDialog";
import { PlanPageHeaderNav } from "./today/planPageHeaderNav";
import { createProjectLabels, createRoutineLabels, createTimeLabels } from "./today/utils/todayPageUtils";
import {
  apiSetStartTimeBlockNumber,
  apiSetEndTimeBlockNumber,
  apiSetPlanBlockType,
  localSetDefaultRoleIdForAddTaskToPlan,
  verifyServerMatchesClient,
  onDoEndTimerPromptAction,
  TodayOptimisticAPIContext,
} from "./today/api/today.api.plan";
import { apiUpdateProject, apiAddProject } from "./today/api/today.api.project";
import {
  apiToggleTaskCompletion,
  apiDeletePlannedTask,
  apiUngroupTask,
  apiEditPlannedTaskName,
} from "./today/api/today.api.task";
import {
  apiSetAllTaskGroupsCollapsed,
  apiReorderTaskGroup,
  apiSetUserTaskGroupCollapsed,
  apiGroupTask,
  apiSetTimeBlockCount,
  apiSetCompletedTimeBlockCount,
  apiMoveIncompleteTasksOfTaskGroupToDate,
  apiAssignProjectToTaskGroup,
  apiSetUserTaskGroupStartTimeBlockNumber,
  apiSetUserTaskGroupGrayTime,
  apiAddTaskToGroup,
  localSetSelectedTaskGroup,
  apiSetUserTaskGroupName,
  apiAddNewTimeCardToPlan,
  apiDeleteTimeCardFromPlan,
  apiSetTaskGroupRole,
} from "./today/api/today.api.taskGroup";
import { PromptForTimeBlockDialog } from "../../components/dialogs/promptForTimeBlockDialog";
import { selectSettingsState } from "./settings/settingsSlice";
import { localSetDefaultRoleIdForBacklogAddTaskToPlan } from "./today/api/today.api.backlog";
import { routinesForDay } from "./today/routinesStateUtils";
import { DailyRoutinesPrompt } from "./today/dailyRoutinesPrompt";

export const TodayPage: React.FC = () => {
  const { dateParam } = useParams();
  const navigate = useNavigate();
  const [compact, setCompact] = React.useState(Cookies.get("compactDashboard") === "true");
  Cookies.set("compactDashboard", compact.toString());

  const [showRoutines, setShowRoutines] = React.useState(Cookies.get("showRoutines") !== "false");
  Cookies.set("showRoutines", showRoutines.toString());

  const [endTimerDialogOpen, setEndTimerDialogOpen] = React.useState(false);

  const planDate = dateParam ? new Date(dateParam.replaceAll("-", "/")) : new Date();
  useEffect(() => {
    if (!dateParam) {
      navigateToDate(new Date());
    }
  });
  const tickingSoundFile = "/sound/timer-ticking.mp3";
  const dingSoundFile = "/sound/timer-ding.mp3";

  const [roleView, setRoleView] = React.useState(getPlanToolbarCookies().roleView);
  const [roleViewCompleted, setRoleViewCompleted] = React.useState(getPlanToolbarCookies().roleViewCompleted);
  const [taskGroupForAction, setTaskGroupForAction] = React.useState<PlanTaskGroup | undefined>(undefined);

  const [promptForRoutineNameIsOpen, setPromptForRoutineNameIsOpen] = React.useState(false);
  const [promptForStartTimeIsOpen, setPromptForStartTimeIsOpen] = React.useState(false);
  const [promptForMoveTaskGroupToDateIsOpen, setPromptForMoveTaskGroupToDateIsOpen] = React.useState(false);
  const [promptForProjectIsOpen, setPromptForProjectIsOpen] = React.useState(false);
  const [editProjectDialogIsOpen, setEditProjectDialogIsOpen] = React.useState(false);

  const dispatch = useAppDispatch();
  const todayPageState = useAppSelector(selectTodayPageState);
  const backlogState = useAppSelector(selectBacklogState);
  const routinesState = useAppSelector(selectRoutinesState);
  const appState = useAppSelector(selectAppState);
  const settingsState = useAppSelector(selectSettingsState);

  const { data, loading, error } = useTodayPageQuery({
    variables: { date: formatDateSimple(planDate) },
    fetchPolicy: "network-only",
  });

  const audioRef = React.useRef<HTMLAudioElement>(null);

  const shouldBlockPlanUI = shouldBlockPlanUIFromState(todayPageState, backlogState);

  const scrollTracker = useVerticalScrollTracker(250);
  const dashBoardRef = useRef<HTMLDivElement | null>(null);
  const [initialScrollOffsetTop, setInitialScrollOffsetTop] = React.useState(0);
  const [showScrollShadow, setShowScrollShadow] = React.useState(false);
  useEffect(() => {
    if (dashBoardRef?.current) {
      if (initialScrollOffsetTop === 0) {
        setInitialScrollOffsetTop(dashBoardRef.current.offsetTop);
      }
      const shouldShowScrollShadow = dashBoardRef.current.offsetTop !== initialScrollOffsetTop;
      if (showScrollShadow !== shouldShowScrollShadow) {
        setShowScrollShadow(shouldShowScrollShadow);
      }

      const shouldHideAppBar = window.scrollY > 25;
      if (appState.appBarHidden !== shouldHideAppBar) {
        dispatch(setAppBarHidden(shouldHideAppBar));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTracker, dashBoardRef?.current?.offsetTop]);

  // Dashboard timer
  useEffect(() => {
    return timerEffectDispatch(todayPageState.timerState, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todayPageState.timerState]);

  // You need an effect to deal with loading state because you want to change state
  // via a dispatch while loading. You can't do that in the render function.
  useEffect(() => {
    if (loading) {
      dispatch(clearTodayPageState());
    } else if (data && !todayPageState.initialized) {
      dispatch(
        initializeTodayPage({
          data,
          date: formatDateSimple(planDate),
          ...getTimerCookies(),
          ...getPlanToolbarCookies(),
        }),
      );
    }
  });

  // Navigate to today and bring up end timer prompt when timer ends
  useEffect(() => {
    if (todayPageState.timerState === TimerState.Running) {
      if (todayPageState.secondsLeftOnTimer === 0 && !endTimerDialogOpen) {
        if (dateParam && compareDates(new Date(), new Date(dateParam)) !== 0) {
          navigateToDate(new Date());
          setEndTimerDialogOpen(true);
        } else {
          setEndTimerDialogOpen(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todayPageState.timerState, todayPageState.secondsLeftOnTimer, endTimerDialogOpen, dateParam]);

  const loadingPage = () => {
    // This is a function, not a component so that the DOM differencing
    // can reuse the same element. As we go route to route, any sound
    // playing should continue to play.
    // The key prop in the audio element is also helping this happen.
    return (
      <AppLayout chosen="Daily Plan">
        <audio key="audio" ref={audioRef} src={tickingSoundFile} loop />
      </AppLayout>
    );
  };

  const onAddTimeCardToPlan = (apiContext: TodayOptimisticAPIContext, name: string, roleId: string) => {
    const { dispatch } = apiContext;
    apiAddNewTimeCardToPlan(apiContext, name, roleId, settingsState.grayIsDefault, (addTaskInFlight: boolean) => {
      dispatch(setAddTaskInFlight({ addTaskInFlight }));
    });
  };

  if (loading) {
    return loadingPage();
  } else if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  } else if (!data) {
    return <AppErrorPage errorMessage={"no data"} />;
  } else if (!todayPageState.initialized) {
    return loadingPage();
  }

  setTimerCookies(todayPageState);
  setPlanToolbarCookies({
    selectedRoleIds: Object.values(todayPageState.selectedRoles)
      ?.map((r) => r?.id ?? "")
      .filter((id) => id !== ""),
    roleView,
    roleViewCompleted,
    defaultRoleId: todayPageState.defaultRoleId,
  });

  const rolesList = Object.values(todayPageState.roles).filter((role): role is PlanRole => !!role);

  const playTickingSound = () => {
    if (audioRef?.current) {
      audioRef.current.loop = true;
      audioRef.current.src = tickingSoundFile;
      audioRef.current.play();
    }
  };

  const playDingSound = () => {
    if (audioRef?.current) {
      audioRef.current.loop = false;
      audioRef.current.src = dingSoundFile;
      audioRef.current.play();
    }
  };

  if (todayPageState.timerState !== TimerState.Running) {
    if (audioRef?.current) {
      const playing = !audioRef.current.paused;
      if (playing && audioRef.current.loop) {
        playDingSound();
      }
    }
  }

  function navigateToDate(date: Date) {
    navigate(`/date/${encodeURIComponent(formatDateSimple(date))}`);
  }

  const makeAPIContext = (client: ApolloClient<object>): TodayOptimisticAPIContext => {
    return { client, todayPageState, backlogState, routinesState, settingsState, dispatch };
  };

  const guideRailAPISetters = (apiContext: TodayOptimisticAPIContext): GuideRailAPISetters => {
    return {
      setStartTimeBlockNumber: (startTimeBlockNumber: number) => {
        apiSetStartTimeBlockNumber(apiContext, startTimeBlockNumber);
      },
      setEndTimeBlockNumber: (endTimeBlockNumber: number) => {
        apiSetEndTimeBlockNumber(apiContext, endTimeBlockNumber);
      },
      setBlockType: (blockNumber: number, blockType: RoleBlockType) => {
        apiSetPlanBlockType(apiContext, blockNumber, blockType);
      },
    };
  };

  const dailyRoutines = routinesForDay(routinesState, todayPageState.taskGroups, planDate);

  return (
    <AppLayout chosen="Daily Plan">
      <Stack sx={{ mb: 2 }}>
        <Stack
          ref={dashBoardRef}
          position="sticky"
          top="0px"
          sx={{
            backgroundColor: "#eee",
            zIndex: 10,
            boxShadow: showScrollShadow ? "0 4px 5px -5px black" : undefined,
          }}
        >
          <PlanPageHeaderNav
            currentDate={planDate}
            appBarHidden={appState.appBarHidden}
            sidebarDrawerIsOpen={appState.sidebarDrawerIsOpen}
            openSidebarDrawer={() => dispatch(setSidebarDrawerIsOpen(true))}
            openBacklogDrawer={() => dispatch(setBacklogDrawerIsOpen(true))}
            navigateToDate={navigateToDate}
          />
          <ApolloConsumer>
            {(client) => (
              <PlanPageDashboard
                date={planDate}
                compact={compact}
                setCompact={(compact: boolean) => setCompact(compact)}
                selectedRoles={todayPageState.selectedRoles}
                secondsLeftOnTimer={todayPageState.secondsLeftOnTimer}
                totalTimeForTimer={timeForTimerType(todayPageState.timerType)}
                timerState={todayPageState.timerState}
                timerType={todayPageState.timerType}
                timerRoleId={todayPageState.timerRoleId}
                setTimerRoleId={(roleId) => dispatch(setTimerRoleId({ roleId }))}
                setTimerType={(timerType) => dispatch(setTimerType({ timerType }))}
                roles={todayPageState.roles}
                rolesList={rolesList}
                roleBlockCounts={todayPageState.roleBlockCounts}
                completedTimeBlockCount={(role) => totalTimeBlocksCompleted(todayPageState, role)}
                grayCompletedTimeBlockCount={(role) => grayTimeBlocksCompleted(todayPageState, role)}
                weekTimeBlocks={data.weekPlanTimeBlockCounts}
                blocksLeftForRole={(time, role) => blocksLeftForRole(todayPageState, planDate, time, role)}
                blocksPastForRole={(time, role) => blocksPastForRole(todayPageState, planDate, time, role)}
                blocksLeftForRoleInTasks={(role) => blocksLeftForRoleInPlan(todayPageState, role)}
                navigateToDaysAgo={(daysAgo) => {
                  const date = new Date(planDate);
                  date.setDate(date.getDate() - daysAgo);
                  navigateToDate(date);
                }}
                startTimer={() => {
                  if (todayPageState.timerState !== TimerState.Running) {
                    dispatch(setTimerState({ timerState: TimerState.Running }));
                    playTickingSound();
                  }
                }}
                pauseTimer={() => {
                  if (todayPageState.timerState === TimerState.Running) {
                    dispatch(setTimerState({ timerState: TimerState.Paused }));
                  }
                  audioRef.current?.pause();
                }}
                resetTimer={() => {
                  dispatch(setTimerState({ timerState: TimerState.Stopped }));
                  audioRef.current?.pause();
                }}
                endTimer={() => {
                  dispatch(timerEnd({ dateISOString: new Date().toISOString() }));
                }}
              />
            )}
          </ApolloConsumer>
          <ApolloConsumer>
            {(client) => (
              <Stack direction="row" sx={{ mx: "auto" }}>
                <GuideRail
                  {...guideRailAPISetters(makeAPIContext(client))}
                  guideRailDate={planDate}
                  startTimeBlockNumber={
                    todayPageState.dailyPlanId === ""
                      ? settingsState.defaultStartTimeBlockNumber
                      : todayPageState.startTimeBlockNumber
                  }
                  endTimeBlockNumber={
                    todayPageState.dailyPlanId === ""
                      ? settingsState.defaultEndTimeBlockNumber
                      : todayPageState.endTimeBlockNumber
                  }
                  rolesList={rolesList}
                  plannedBlocks={todayPageState.plannedBlocks}
                />
              </Stack>
            )}
          </ApolloConsumer>
          <ApolloConsumer>
            {(client) => (
              <PlanTasksToolbar
                dailyPlanId={todayPageState.dailyPlanId}
                rolesList={rolesList}
                roles={todayPageState.roles}
                selectedRoles={todayPageState.selectedRoles}
                roleColor={colorForRole(todayPageState.roles, todayPageState.defaultRoleId)}
                roleView={roleView}
                setRoleView={setRoleView}
                roleViewCompleted={roleViewCompleted}
                setRoleViewCompleted={setRoleViewCompleted}
                toggleRoleIdSelectedInFilter={(roleId: string) => dispatch(toggleRoleIdSelected({ roleId }))}
                onAddTimeCardToPlan={(name: string, roleId: string) =>
                  onAddTimeCardToPlan(makeAPIContext(client), name, roleId)
                }
                defaultRoleIdForAddTask={todayPageState.defaultRoleId}
                setDefaultRoleIdForAddTask={(roleId: string) => {
                  localSetDefaultRoleIdForAddTaskToPlan(roleId, dispatch);
                  localSetDefaultRoleIdForBacklogAddTaskToPlan(roleId, dispatch);
                }}
                setAllTaskgroupsCollapsed={(collapsed: boolean) =>
                  apiSetAllTaskGroupsCollapsed(makeAPIContext(client), collapsed)
                }
                verifyServerMatchesClient={() => verifyServerMatchesClient(makeAPIContext(client), planDate)}
                showRoutines={showRoutines}
                toggleShowRoutines={() => {
                  setShowRoutines(!showRoutines);
                }}
                numberOfUnaddedRoutines={dailyRoutines.length}
              />
            )}
          </ApolloConsumer>
          <ApolloConsumer>
            {(client) => (
              <DailyRoutinesPrompt
                planDate={planDate}
                showRoutines={showRoutines}
                routinesToDisplay={dailyRoutines}
                onClick={(routine) => {
                  apiPlanRoutine(makeAPIContext(client), routine);
                }}
              />
            )}
          </ApolloConsumer>
        </Stack>
        <Stack sx={{ zIndex: 1, mx: 1 }}>
          <ApolloConsumer>
            {(client) => (
              <Stack>
                <PlannedTaskGroups
                  taskGroups={todayPageState.taskGroups}
                  dropEnd={(result: DropResult) => {
                    if (result.destination && result.source.index !== result.destination.index) {
                      apiReorderTaskGroup(makeAPIContext(client), result.source.index, result.destination.index);
                    }
                  }}
                  editingInProgress={todayPageState.editingInProgress}
                  roles={todayPageState.roles}
                  rolesList={rolesList}
                  selectedRoles={todayPageState.selectedRoles}
                  roleView={roleView}
                  roleViewCompleted={roleViewCompleted}
                  toggleTaskGroupCollapsed={(taskGroup) =>
                    apiSetUserTaskGroupCollapsed(makeAPIContext(client), taskGroup, !taskGroup.collapsed)
                  }
                  isEditingTaskId={todayPageState.isEditingTaskId}
                  setIsEditingTaskId={(taskId, isEditing) => {
                    dispatch(setIsEditingTaskId({ taskId, isEditing }));
                  }}
                  editTaskName={(task, newName) => apiEditPlannedTaskName(makeAPIContext(client), task, newName)}
                  toggleTaskCompletion={(task) => apiToggleTaskCompletion(makeAPIContext(client), task)}
                  unplanTask={(task, taskGroup) =>
                    apiUnplanTask(makeAPIContext(client), taskGroup, task, settingsState.grayIsDefault)
                  }
                  deleteTask={(task, taskGroup) =>
                    apiDeletePlannedTask(makeAPIContext(client), taskGroup, task, settingsState.grayIsDefault)
                  }
                  groupTask={(task, taskGroup, inFilter) => {
                    const oldGroupIndex = todayPageState.taskGroups.findIndex((tg) => tg.id === taskGroup.id);
                    const newGroup = groupThatWillBeGrouped(todayPageState, oldGroupIndex, taskGroup, inFilter);
                    if (newGroup) {
                      apiGroupTask(makeAPIContext(client), taskGroup, newGroup, task);
                    }
                  }}
                  ungroupTask={(task, taskGroup) => apiUngroupTask(makeAPIContext(client), taskGroup, task)}
                  deleteTaskGroup={(taskGroup) => apiDeleteTimeCardFromPlan(makeAPIContext(client), taskGroup)}
                  autoAddModeGroup={todayPageState.autoAddModeGroup}
                  setAutoAddModeGroup={(autoAddModeGroup) => dispatch(setAutoAddModeGroup({ autoAddModeGroup }))}
                  autoAddTask={(group) => {
                    apiAddTaskToGroup(makeAPIContext(client), group);
                  }}
                  shouldBlockUI={shouldBlockPlanUI}
                  setEditingInProgress={(editingInProgress: boolean) => {
                    dispatch(setEditingInProgress({ editingInProgress }));
                  }}
                  setTaskGroupRole={(taskGroup: PlanTaskGroup, role: PlanRole) => {
                    apiSetTaskGroupRole(makeAPIContext(client), taskGroup, role);
                  }}
                  onTimeBlockClick={(taskGroup, timeBlockIndex) =>
                    apiSetTimeBlockCount(makeAPIContext(client), taskGroup, timeBlockIndex + 1)
                  }
                  onCompletedTimeBlockClick={(taskGroup, timeBlockIndex) =>
                    apiSetCompletedTimeBlockCount(makeAPIContext(client), taskGroup, timeBlockIndex + 1)
                  }
                  colorForRole={(roleId) => colorForRoleInState(todayPageState, roleId)}
                  canBeGrouped={(i, taskGroup, inFilter) => onCanBeGrouped(todayPageState, i, taskGroup, inFilter)}
                  labelsForTaskGroup={(taskGroup) => {
                    return [
                      ...createTimeLabels(taskGroup, () => {
                        setTaskGroupForAction(taskGroup);
                        setPromptForStartTimeIsOpen(true);
                      }),
                      ...createProjectLabels(todayPageState, taskGroup, () => {
                        setTaskGroupForAction(taskGroup);
                        setEditProjectDialogIsOpen(true);
                      }),
                      ...createRoutineLabels(todayPageState, routinesState, taskGroup),
                    ];
                  }}
                  selectedTaskGroupId={todayPageState.selectedTaskGroupId}
                  onSelectTaskGroup={(taskGroupId) => {
                    localSetSelectedTaskGroup(taskGroupId, dispatch);
                  }}
                  setStartTime={(taskGroup) => {
                    setTaskGroupForAction(taskGroup);
                    setPromptForStartTimeIsOpen(true);
                  }}
                  setGrayTime={(taskGroup, isGrayTime) => {
                    apiSetUserTaskGroupGrayTime(makeAPIContext(client), taskGroup, isGrayTime);
                  }}
                  moveIncompleteTasks={(taskGroup) => {
                    setTaskGroupForAction(taskGroup);
                    setPromptForMoveTaskGroupToDateIsOpen(true);
                  }}
                  addTask={(taskGroup) => {
                    setTaskGroupForAction(taskGroup);
                    apiAddTaskToGroup(makeAPIContext(client), taskGroup);
                  }}
                  putTaskGroupIntoProject={(taskGroup) => {
                    setTaskGroupForAction(taskGroup);
                    setPromptForProjectIsOpen(true);
                  }}
                  makeTaskGroupIntoRoutine={(taskGroup) => {
                    setTaskGroupForAction(taskGroup);
                    setPromptForRoutineNameIsOpen(true);
                  }}
                  updateRoutineFromTaskGroup={(taskGroup) => {
                    apiUpdateRoutineFromTaskGroup(makeAPIContext(client), taskGroup);
                  }}
                  editTaskGroupName={(taskGroup, name) => {
                    apiSetUserTaskGroupName(makeAPIContext(client), taskGroup, name);
                  }}
                  taskGroupLabels={[]}
                />
              </Stack>
            )}
          </ApolloConsumer>
        </Stack>
      </Stack>
      <AppSnackbar />
      <audio key="audio" ref={audioRef} src={tickingSoundFile} loop />
      <ApolloConsumer>
        {(client) => (
          <>
            <EndTimerPromptDialog
              open={endTimerDialogOpen}
              roles={todayPageState.roles}
              onClose={() => setEndTimerDialogOpen(false)}
              taskGroupToMark={firstGroupWithIncompleteTasksInTimerRole(todayPageState)}
              shouldAllowAutoCompleteTimeBlock={todayPageState.timerType === TimerType.Work}
              onStartWorkBlock={(autoCompleteTimeBlock: boolean) => {
                onDoEndTimerPromptAction(makeAPIContext(client), autoCompleteTimeBlock, TimerType.Work);
                playTickingSound();
              }}
              onStartShortBreak={(autoCompleteTimeBlock: boolean) => {
                onDoEndTimerPromptAction(makeAPIContext(client), autoCompleteTimeBlock, TimerType.ShortBreak);
                playTickingSound();
              }}
              onStartLongBreak={(autoCompleteTimeBlock: boolean) => {
                onDoEndTimerPromptAction(makeAPIContext(client), autoCompleteTimeBlock, TimerType.LongBreak);
                playTickingSound();
              }}
              onDoNothing={(autoCompleteTimeBlock: boolean) => {
                onDoEndTimerPromptAction(makeAPIContext(client), autoCompleteTimeBlock, null);
              }}
            />
            <PromptForTextDialog
              open={promptForRoutineNameIsOpen}
              onClose={() => {
                setPromptForRoutineNameIsOpen(false);
              }}
              label="Routine Name"
              buttonLabel="Create Routine"
              onSave={async (text: string, onSuccess: () => void, onError: (msg: string) => void) => {
                if (taskGroupForAction) {
                  apiMakeTaskGroupIntoRoutine(
                    makeAPIContext(client),
                    taskGroupForAction,
                    text,
                    () => {
                      setTaskGroupForAction(undefined);
                      onSuccess();
                    },
                    onError,
                  );
                }
              }}
              defaultText={taskGroupForAction?.name ?? undefined}
            />
            <PromptForDateDialog
              title="Move tasks to date"
              currentDate={planDate}
              open={promptForMoveTaskGroupToDateIsOpen}
              onClose={() => setPromptForMoveTaskGroupToDateIsOpen(false)}
              buttonLabel={"Move tasks"}
              onSave={async (date: Date, onSuccess: () => void, onError: (msg: string) => void): Promise<void> => {
                if (taskGroupForAction) {
                  apiMoveIncompleteTasksOfTaskGroupToDate(
                    makeAPIContext(client),
                    taskGroupForAction,
                    date,
                    settingsState.grayIsDefault,
                    () => {
                      setTaskGroupForAction(undefined);
                      onSuccess();
                    },
                    onError,
                  );
                }
              }}
            />
            <PromptForTimeBlockDialog
              title="Set Start Time for Group"
              currentTimeBlock={taskGroupForAction?.startTimeBlockNumber}
              open={promptForStartTimeIsOpen}
              onClose={() => setPromptForStartTimeIsOpen(false)}
              buttonLabel="Set Start Time"
              onSave={async (timeBlock?: number): Promise<void> => {
                if (taskGroupForAction) {
                  setTaskGroupForAction(undefined);
                  apiSetUserTaskGroupStartTimeBlockNumber(makeAPIContext(client), taskGroupForAction, timeBlock);
                }
                setPromptForStartTimeIsOpen(false);
              }}
            />
            <EditProjectDialog
              title="Edit Project"
              open={editProjectDialogIsOpen}
              saveAndClose={(project: PlanProject) => {
                if (taskGroupForAction?.projectId) {
                  apiUpdateProject(makeAPIContext(client), project);
                }
                setEditProjectDialogIsOpen(false);
              }}
              cancel={() => {
                setEditProjectDialogIsOpen(false);
              }}
              project={todayPageState.projects[taskGroupForAction?.projectId ?? ""] ?? nullPlanProject}
            />
            <PromptForProjectDialog
              projects={generateProjectsList(todayPageState.projects)}
              canClearProject={taskGroupForAction?.projectId !== undefined}
              roleId={taskGroupForAction?.roleId ?? ""}
              open={promptForProjectIsOpen}
              onClose={() => setPromptForProjectIsOpen(false)}
              onSave={async (
                project: PlanProject | undefined,
                onSuccess: () => void,
                onError: (msg: string) => void,
              ) => {
                if (taskGroupForAction) {
                  apiAssignProjectToTaskGroup(
                    makeAPIContext(client),
                    taskGroupForAction,
                    project,
                    () => {
                      setTaskGroupForAction(undefined);
                      onSuccess();
                    },
                    onError,
                  );
                }
              }}
              addProject={async (project: PlanProject, onSuccess: () => void) => {
                apiAddProject(makeAPIContext(client), project, onSuccess, () => {});
              }}
              buttonLabel="Assign"
            />
          </>
        )}
      </ApolloConsumer>
    </AppLayout>
  );
};
