import React from "react";
import { Dialog, Button, Stack, Typography, Checkbox, checkboxClasses } from "@mui/material";
import { Dictionary } from "@reduxjs/toolkit";
import { PlanRole, PlanTaskGroup } from "../../../util/modelTypes";
import { colorForRole } from "./todayPageStateUtils";
import { lightenColor } from "../../../util/colorUtils";

interface Props {
  open: boolean;
  roles: Dictionary<PlanRole>;
  onClose: () => void;
  taskGroupToMark: PlanTaskGroup | undefined;
  shouldAllowAutoCompleteTimeBlock: boolean;
  onStartWorkBlock: (autoCompleteTimeBlock: boolean) => void;
  onStartShortBreak: (autoCompleteTimeBlock: boolean) => void;
  onStartLongBreak: (autoCompleteTimeBlock: boolean) => void;
  onDoNothing: (autoCompleteTimeBlock: boolean) => void;
}

export const EndTimerPromptDialog: React.FC<Props> = ({
  open,
  roles,
  onClose,
  taskGroupToMark,
  shouldAllowAutoCompleteTimeBlock,
  onStartWorkBlock,
  onStartShortBreak,
  onStartLongBreak,
  onDoNothing,
}) => {
  const [autoCompleteTimeBlock, setAutoCompleteTimeBlock] = React.useState(true);

  const closeWithAction = (action: (autoCompleteTimeBlock: boolean) => void) => {
    action(shouldAllowAutoCompleteTimeBlock ? autoCompleteTimeBlock : false);
    onClose();
    setAutoCompleteTimeBlock(true);
  };

  const roleId = taskGroupToMark ? taskGroupToMark.roleId : undefined;
  const roleColor = roleId ? colorForRole(roles, roleId) : undefined;

  const taskNameToMark = taskGroupToMark
    ? taskGroupToMark.name ?? (taskGroupToMark.tasks.length > 0 ? taskGroupToMark.tasks[0].name : "<unnamed time card>")
    : undefined;

  return (
    <Dialog open={open} onClose={onClose}>
      <Stack sx={{ p: 4 }} spacing={2}>
        <Typography variant="h5">What would you like to do now?</Typography>
        {shouldAllowAutoCompleteTimeBlock && taskNameToMark !== undefined && (
          <Stack direction="row" alignItems="center">
            <Checkbox
              checked={autoCompleteTimeBlock}
              onChange={(_, checked) => {
                setAutoCompleteTimeBlock(checked);
              }}
              sx={{
                [`&, &.${checkboxClasses.checked}`]: {
                  color: roleColor,
                },
              }}
            />
            <Typography>
              Mark the next time block for <strong>{taskNameToMark}</strong>
            </Typography>
          </Stack>
        )}
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            sx={{ backgroundColor: roleColor, ":hover": { backgroundColor: lightenColor(roleColor) } }}
            onClick={() => closeWithAction(onStartWorkBlock)}
          >
            Focus Timer
          </Button>
          <Button sx={{ color: roleColor }} onClick={() => closeWithAction(onStartShortBreak)}>
            Short Break Timer
          </Button>
          <Button sx={{ color: roleColor }} onClick={() => closeWithAction(onStartLongBreak)}>
            Long Break Timer
          </Button>
          <Button sx={{ color: roleColor }} onClick={() => closeWithAction(onDoNothing)}>
            No Timer
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
