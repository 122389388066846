import {
  LIST_ORDER_SPACING,
  addNewTaskToGroup,
  addNewTaskToPlan,
  addTaskGroupWithTasksToPlan,
  addTimeCardToPlan,
  deletePlannedTask,
  deleteTaskGroupFromPlan,
  moveTaskToGroup,
  removeIncompleteTasksOfTaskGroup,
  reorderTaskGroup,
  setAllTaskGroupsCollapsed,
  setAutoAddModeGroup,
  setDailyPlanId,
  setEndTimeBlockNumber,
  setIsEditingTaskId,
  setSelectedTaskGroup,
  setStartTimeBlockNumber,
  setTaskGroupCollapsed,
  setTaskGroupCompletedTimeBlockCount,
  setTaskGroupGrayTime,
  setTaskGroupName,
  setTaskGroupProject,
  setTaskGroupRole,
  setTaskGroupStartTimeBlockNumber,
  setTaskGroupTimeBlockCount,
  updateProjectScoreboard,
} from "../../todayPageSlice";
import {
  requestAddNewTaskToGroup,
  requestAddTimeCardToPlan,
  requestDeleteTimeCardFromPlan,
  requestGetProjectScoreboard,
  requestMoveIncompleteTasksOfTaskGroupToDate,
  requestMoveUserTaskToGroup,
  requestSetUserPlanCollapsedForTaskGroups,
  requestSetUserTaskGroupCollapsed,
  requestSetUserTaskGroupCompletedTimeBlockCount,
  requestSetUserTaskGroupGrayTime,
  requestSetUserTaskGroupName,
  requestSetUserTaskGroupOrder,
  requestSetUserTaskGroupProject,
  requestSetUserTaskGroupRole,
  requestSetUserTaskGroupStartTimeBlockNumber,
  requestSetUserTaskGroupTimeBlockCount,
  requestUnsetUserTaskGroupProject,
} from "../../../../generated/graphqlWrappers";
import { showSnackbarError } from "../../../../components/appSnackbarSlice";
import { PlanProject, PlanRole, PlanTask, PlanTaskGroup } from "../../../../util/modelTypes";
import { calculateTaskGroupOrderAfterReorder } from "../todayPageStateUtils";
import { optimisticUpdateAndServerRequestWithUndo } from "./api.utils";
import { formatDateSimple } from "../../../../util/dateUtils";
import { TodayOptimisticAPIContext } from "./today.api.plan";
import { uuidForId } from "../../../../util/uuidUtils";
import { AppDispatch } from "../../../../store/store";
import { nonBlankOrNull } from "../../../../util/stringUtils";

export const localSetSelectedTaskGroup = (taskGroupId: string, dispatch: AppDispatch) => {
  dispatch(setSelectedTaskGroup({ taskGroupId }));
};

export const apiUpdateProjectScoreboard = (apiContext: TodayOptimisticAPIContext, projectId: string | undefined) => {
  const { client, dispatch } = apiContext;
  if (projectId !== undefined) {
    requestGetProjectScoreboard(
      client,
      { projectId },
      (result) => {
        dispatch(updateProjectScoreboard({ projectId, dataForScoreboard: result.dataForScoreboard }));
      },
      (err) => {},
    );
  }
};

export function apiAddNewTimeCardToPlan(
  apiContext: TodayOptimisticAPIContext,
  name: string,
  roleId: string,
  grayIsDefault: boolean,
  setAddTaskInFlight: (inFlight: boolean) => void,
) {
  const { client, todayPageState, settingsState, dispatch } = apiContext;
  const hasDailyPlan = todayPageState.dailyPlanId !== "";
  const oldDailyPlanId = todayPageState.dailyPlanId;
  const dailyPlanId = hasDailyPlan ? todayPageState.dailyPlanId : uuidForId();

  const taskGroupId = uuidForId();
  const taskGroupOrder = (todayPageState.taskGroups[todayPageState.taskGroups.length - 1]?.order ?? 0) + 1000;
  const isGrayTime = grayIsDefault;

  if (!hasDailyPlan) {
    dispatch(setDailyPlanId({ dailyPlanId }));
    dispatch(setStartTimeBlockNumber({ startTimeBlockNumber: settingsState.defaultStartTimeBlockNumber }));
    dispatch(setEndTimeBlockNumber({ endTimeBlockNumber: settingsState.defaultEndTimeBlockNumber }));
  }

  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    onStart: () => setAddTaskInFlight(true),
    onEnd: () => setAddTaskInFlight(false),
    optimisticUpdate: addTimeCardToPlan({ taskGroupId, taskGroupOrder, roleId, name, isGrayTime: grayIsDefault }),
    request: requestAddTimeCardToPlan,
    variables: {
      dailyPlanId,
      date: todayPageState.date,
      dailyPlanTimeCardId: taskGroupId,
      order: taskGroupOrder,
      roleId,
      name,
      isGrayTime,
    },
    undo: [deleteTaskGroupFromPlan({ taskGroupId }), setDailyPlanId({ dailyPlanId: oldDailyPlanId })],
  });
}

export const apiDeleteTimeCardFromPlan = (apiContext: TodayOptimisticAPIContext, taskGroup: PlanTaskGroup) => {
  const { client, dispatch } = apiContext;

  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: deleteTaskGroupFromPlan({ taskGroupId: taskGroup.id }),
    request: requestDeleteTimeCardFromPlan,
    variables: { dailyPlanTimeCardId: taskGroup.id },
    undo: [addTaskGroupWithTasksToPlan({ taskGroup })],
  });
};

export const apiGroupTask = (
  apiContext: TodayOptimisticAPIContext,
  taskGroup: PlanTaskGroup,
  newTaskGroup: PlanTaskGroup,
  task: PlanTask,
) => {
  const { client, todayPageState, dispatch } = apiContext;
  const oldTaskGroupId = taskGroup.id;
  const oldTaskGroupOrder = taskGroup.order;
  const oldTimeBlockCount = taskGroup.timeBlockCount;
  const oldCompletedTimeBlockCount = taskGroup.completedTimeBlockCount;
  const oldRoutineId = taskGroup.routineId;
  const oldProjectId = taskGroup.projectId;
  const oldGrayTime = taskGroup.isGrayTime;
  const oldTaskOrder = task.order;
  const newTaskOrder = newTaskGroup.tasks[newTaskGroup.tasks.length - 1].order + 1000;

  if (!newTaskGroup) {
    dispatch(showSnackbarError("Cannot group task"));
    return;
  }

  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: moveTaskToGroup({
      taskId: task.id,
      taskGroupId: newTaskGroup.id,
      groupOrder: newTaskGroup.order,
      taskOrder: newTaskOrder,
      timeBlockCount: 1,
      completedTimeBlockCount: 0,
      // New taskgroups stay in the project, but not in the routine
      projectId: taskGroup.projectId,
      isGrayTime: newTaskGroup.isGrayTime,
    }),
    request: requestMoveUserTaskToGroup,
    variables: {
      taskId: task.id,
      planId: todayPageState.dailyPlanId,
      dailyPlanTaskGroupId: newTaskGroup.id,
      groupOrder: newTaskGroup.order,
      taskOrder: newTaskOrder,
    },
    undo: moveTaskToGroup({
      taskId: task.id,
      taskGroupId: oldTaskGroupId,
      groupOrder: oldTaskGroupOrder,
      taskOrder: oldTaskOrder,
      timeBlockCount: oldTimeBlockCount,
      completedTimeBlockCount: oldCompletedTimeBlockCount,
      routineId: oldRoutineId,
      projectId: oldProjectId,
      isGrayTime: oldGrayTime,
    }),
  });
};

export const apiSetTaskGroupRole = (
  apiContext: TodayOptimisticAPIContext,
  taskGroup: PlanTaskGroup,
  role: PlanRole,
) => {
  const { client, dispatch } = apiContext;
  const roleId = role.id;
  const oldRoleId = taskGroup.roleId;

  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: setTaskGroupRole({ taskGroupId: taskGroup.id, roleId }),
    request: requestSetUserTaskGroupRole,
    variables: { dailyPlanTaskGroupId: taskGroup.id, roleId },
    undo: setTaskGroupRole({ taskGroupId: taskGroup.id, roleId: oldRoleId }),
  });
};

export const apiSetTimeBlockCount = (
  apiContext: TodayOptimisticAPIContext,
  taskGroup: PlanTaskGroup,
  timeBlockCount: number,
) => {
  const { client, dispatch } = apiContext;
  const oldTimeBlockCount = taskGroup.timeBlockCount;
  dispatch(
    setTaskGroupTimeBlockCount({
      id: taskGroup.id,
      timeBlockCount,
    }),
  );
  requestSetUserTaskGroupTimeBlockCount(
    client,
    { dailyPlanTaskGroupId: taskGroup.id, timeBlockCount },
    () => {},
    (err) => {
      dispatch(
        setTaskGroupTimeBlockCount({
          id: taskGroup.id,
          timeBlockCount: oldTimeBlockCount,
        }),
      );
      dispatch(showSnackbarError(err));
    },
  );
};

export const apiSetCompletedTimeBlockCount = (
  apiContext: TodayOptimisticAPIContext,
  taskGroup: PlanTaskGroup,
  completedTimeBlockCount: number,
) => {
  const { client, dispatch } = apiContext;
  const oldTimeBlockCount = taskGroup.completedTimeBlockCount;

  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: setTaskGroupCompletedTimeBlockCount({
      id: taskGroup.id,
      completedTimeBlockCount,
    }),
    request: requestSetUserTaskGroupCompletedTimeBlockCount,
    variables: { dailyPlanTaskGroupId: taskGroup.id, completedTimeBlockCount },
    onEnd: () => {
      apiUpdateProjectScoreboard(apiContext, taskGroup.projectId);
    },
    undo: setTaskGroupCompletedTimeBlockCount({
      id: taskGroup.id,
      completedTimeBlockCount: oldTimeBlockCount,
    }),
  });
};

export const apiReorderTaskGroup = (apiContext: TodayOptimisticAPIContext, oldIndex: number, newIndex: number) => {
  const { client, todayPageState, dispatch } = apiContext;
  const taskGroup = todayPageState.taskGroups[oldIndex];
  const oldOrder = taskGroup.order;
  const newOrder = calculateTaskGroupOrderAfterReorder(oldIndex, newIndex, todayPageState.taskGroups);

  dispatch(reorderTaskGroup({ oldIndex, newIndex, newOrder }));
  requestSetUserTaskGroupOrder(
    client,
    { dailyPlanTaskGroupId: taskGroup.id, order: newOrder },
    () => {},
    (err) => {
      dispatch(showSnackbarError(err));
      dispatch(reorderTaskGroup({ oldIndex: newIndex, newIndex: oldIndex, newOrder: oldOrder }));
    },
  );
};

export const apiSetUserTaskGroupCollapsed = (
  apiContext: TodayOptimisticAPIContext,
  taskGroup: PlanTaskGroup,
  collapsed: boolean,
) => {
  const { client, dispatch } = apiContext;
  const oldCollapsed = taskGroup.collapsed;

  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: setTaskGroupCollapsed({ taskGroupId: taskGroup.id, collapsed }),
    request: requestSetUserTaskGroupCollapsed,
    variables: { dailyPlanTaskGroupId: taskGroup.id, collapsed },
    undo: setTaskGroupCollapsed({ taskGroupId: taskGroup.id, collapsed: oldCollapsed }),
  });
};

export const apiAddTaskToGroup = (apiContext: TodayOptimisticAPIContext, taskGroup: PlanTaskGroup) => {
  const { client, dispatch } = apiContext;
  const taskId = uuidForId();
  const name = "New Task";
  const order =
    (taskGroup.tasks.length > 0 ? taskGroup.tasks[taskGroup.tasks.length - 1].order : 1000) + LIST_ORDER_SPACING;
  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: [
      addNewTaskToGroup({ taskGroupId: taskGroup.id, taskId, name, order }),
      setAutoAddModeGroup({ autoAddModeGroup: taskGroup.id }),
      setIsEditingTaskId({ taskId, isEditing: true }),
    ],
    request: requestAddNewTaskToGroup,
    variables: { dailyPlanTaskGroupId: taskGroup.id, taskId, name, order },
    undo: [
      deletePlannedTask({ taskId }),
      setAutoAddModeGroup({ autoAddModeGroup: "" }),
      setIsEditingTaskId({ taskId, isEditing: false }),
    ],
  });
};

export const apiSetUserTaskGroupName = (
  apiContext: TodayOptimisticAPIContext,
  taskGroup: PlanTaskGroup,
  newName: string,
) => {
  const { client, dispatch } = apiContext;
  const name = nonBlankOrNull(newName);
  const oldName = taskGroup.name;

  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: setTaskGroupName({ taskGroupId: taskGroup.id, name }),
    request: requestSetUserTaskGroupName,
    variables: { dailyPlanTaskGroupId: taskGroup.id, name },
    undo: setTaskGroupName({ taskGroupId: taskGroup.id, name: oldName }),
  });
};

export const apiSetUserTaskGroupGrayTime = (
  apiContext: TodayOptimisticAPIContext,
  taskGroup: PlanTaskGroup,
  isGrayTime: boolean,
) => {
  const { client, dispatch } = apiContext;
  const oldIsGrayTime = taskGroup.isGrayTime;

  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: setTaskGroupGrayTime({ taskGroupId: taskGroup.id, isGrayTime }),
    request: requestSetUserTaskGroupGrayTime,
    variables: { dailyPlanTaskGroupId: taskGroup.id, isGrayTime },
    undo: setTaskGroupGrayTime({ taskGroupId: taskGroup.id, isGrayTime: oldIsGrayTime }),
  });
};

export const apiSetAllTaskGroupsCollapsed = (apiContext: TodayOptimisticAPIContext, collapsed: boolean) => {
  const { client, todayPageState, dispatch } = apiContext;
  if (todayPageState.dailyPlanId === "") {
    return;
  }
  const undoActions = todayPageState.taskGroups.map((taskGroup) =>
    setTaskGroupCollapsed({ taskGroupId: taskGroup.id, collapsed: taskGroup.collapsed }),
  );

  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: setAllTaskGroupsCollapsed({ collapsed }),
    request: requestSetUserPlanCollapsedForTaskGroups,
    variables: { dailyPlanId: todayPageState.dailyPlanId, collapsed },
    undo: undoActions,
  });
};

export const apiSetUserTaskGroupStartTimeBlockNumber = (
  apiContext: TodayOptimisticAPIContext,
  taskGroup: PlanTaskGroup,
  startTimeBlockNumber?: number,
) => {
  const { client, dispatch } = apiContext;
  const oldStartTimeBlockNumber = taskGroup.startTimeBlockNumber;

  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: setTaskGroupStartTimeBlockNumber({ id: taskGroup.id, startTimeBlockNumber }),
    request: requestSetUserTaskGroupStartTimeBlockNumber,
    variables: { dailyPlanTaskGroupId: taskGroup.id, startTimeBlockNumber },
    undo: setTaskGroupStartTimeBlockNumber({ id: taskGroup.id, startTimeBlockNumber: oldStartTimeBlockNumber }),
  });
};

export const apiMoveIncompleteTasksOfTaskGroupToDate = (
  apiContext: TodayOptimisticAPIContext,
  taskGroup: PlanTaskGroup,
  date: Date,
  grayIsDefault: boolean,
  onSuccess: () => void,
  onError: (err: string) => void,
) => {
  const { client, dispatch } = apiContext;
  const hasIncompleteTasks = taskGroup.tasks.some((task) => !task.completed);
  if (!hasIncompleteTasks) {
    onSuccess();
    return;
  }

  // To undo, we need to add the tasks back to the plan.
  const undoActions = taskGroup.tasks
    .filter((task) => !task.completed)
    .map((task) => {
      return addNewTaskToPlan({
        taskGroupId: taskGroup.id,
        taskGroupOrder: taskGroup.order,
        taskId: task.id,
        roleId: task.roleId,
        name: task.name,
        order: task.order,
        grayIsDefault,
        recoveryData: {
          taskGroupName: taskGroup.name,
          roleId: taskGroup.roleId,
          timeBlockCount: taskGroup.timeBlockCount,
          completedTimeBlockCount: taskGroup.completedTimeBlockCount,
          routineId: taskGroup.routineId,
          projectId: taskGroup.projectId,
          isGrayTime: taskGroup.isGrayTime,
        },
      });
    });

  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: removeIncompleteTasksOfTaskGroup({ taskGroupId: taskGroup.id }),
    request: requestMoveIncompleteTasksOfTaskGroupToDate,
    variables: {
      dailyPlanTaskGroupId: taskGroup.id,
      date: formatDateSimple(date),
    },
    undo: undoActions,
  });

  // we always end on success because errors are handled by the optimistic update
  onSuccess();
};

export const apiAssignProjectToTaskGroup = (
  apiContext: TodayOptimisticAPIContext,
  taskGroup: PlanTaskGroup,
  project: PlanProject | undefined,
  onSuccess: () => void,
  onError: (err: string) => void,
) => {
  const { client, dispatch } = apiContext;
  const oldProjectId = taskGroup.projectId;
  if (project !== undefined) {
    optimisticUpdateAndServerRequestWithUndo({
      client,
      dispatch,
      optimisticUpdate: setTaskGroupProject({ taskGroupId: taskGroup.id, projectId: project.id }),
      request: requestSetUserTaskGroupProject,
      variables: {
        dailyPlanTaskGroupId: taskGroup.id,
        projectId: project.id,
      },
      onEnd: () => {
        apiUpdateProjectScoreboard(apiContext, project.id);
        apiUpdateProjectScoreboard(apiContext, oldProjectId);
      },
      undo: setTaskGroupProject({ taskGroupId: taskGroup.id, projectId: oldProjectId }),
    });
  } else {
    optimisticUpdateAndServerRequestWithUndo({
      client,
      dispatch,
      optimisticUpdate: setTaskGroupProject({ taskGroupId: taskGroup.id, projectId: undefined }),
      request: requestUnsetUserTaskGroupProject,
      variables: {
        dailyPlanTaskGroupId: taskGroup.id,
      },
      onEnd: () => {
        apiUpdateProjectScoreboard(apiContext, oldProjectId);
      },
      undo: setTaskGroupProject({ taskGroupId: taskGroup.id, projectId: oldProjectId }),
    });
  }

  // we always end on success because errors are handled by the optimistic update
  onSuccess();
};
