import React from "react";
import { Box, Button, TextField } from "@mui/material";
import { RoleMenu } from "./roleMenu";
import { PlanRole } from "../../../util/modelTypes";

interface Props {
  roleColor: string;
  rolesList: PlanRole[];
  onAddTimeCardToPlan: (name: string, roleId: string) => void;
  defaultRoleIdForAddTask: string;
  setDefaultRoleIdForAddTask: (roleId: string) => void;
}

export const AddTimeCard: React.FC<Props> = ({
  roleColor,
  rolesList,
  onAddTimeCardToPlan,
  defaultRoleIdForAddTask,
  setDefaultRoleIdForAddTask,
}) => {
  const [newTimeCardName, setNewTimeCardName] = React.useState("");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isRoleMenuOpen, setIsRoleMenuOpen] = React.useState(false);

  const addTimeCardDisabled = false;

  const addTimeCardToPlan = () => {
    onAddTimeCardToPlan(newTimeCardName, defaultRoleIdForAddTask);
    setNewTimeCardName("");
  };

  return (
    <>
      <Box
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setIsRoleMenuOpen(true);
        }}
        sx={{
          p: "12px",
          alignSelf: "center",
          backgroundColor: roleColor,
        }}
        data-testid="NewTimeCardRoleBox"
      />
      <TextField
        placeholder="new time card"
        sx={{ flexGrow: 1 }}
        onChange={(e) => {
          setNewTimeCardName(e.target.value);
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            addTimeCardToPlan();
          }
        }}
        value={newTimeCardName}
        data-testid="NewTimeCardField"
      />
      <RoleMenu
        open={isRoleMenuOpen}
        anchorEl={anchorEl}
        onClose={() => setIsRoleMenuOpen(false)}
        roles={rolesList}
        selectedRoleIndex={rolesList.findIndex((r) => r.id === defaultRoleIdForAddTask)}
        setSelectedRole={(roleIndex: number) => {
          setAnchorEl(null);
          setIsRoleMenuOpen(false);
          setDefaultRoleIdForAddTask(rolesList[roleIndex].id);
        }}
      />
      <Button
        variant="contained"
        sx={{ backgroundColor: roleColor }}
        disabled={addTimeCardDisabled}
        onClick={() => {
          addTimeCardToPlan();
        }}
        data-testid="NewTimeCardButton"
      >
        Add
      </Button>
    </>
  );
};
