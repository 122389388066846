import {
  MILLISECONDS_IN_A_WEEK,
  SECONDS_IN_A_TIMEBLOCK,
  formatSecondsTimeSimple,
  parseDateSimple,
} from "../../../../util/dateUtils";
import { PlanProject, PlanProjectDataForScoreboard, PlanTaskGroup } from "../../../../util/modelTypes";
import { RoutinesState } from "../../routinesSlice";
import { TodayPageState } from "../../todayPageSlice";
import { TaskGroupLabel } from "../draggableTaskGroup";
import { colorForRoleInState } from "../todayPageStateUtils";
import ScheduleIcon from "@mui/icons-material/Schedule";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import LoopIcon from "@mui/icons-material/Loop";

export const createTimeLabels = (taskGroup: PlanTaskGroup, onClick: () => void): TaskGroupLabel[] => {
  if (taskGroup.startTimeBlockNumber !== undefined) {
    return [
      {
        icon: <ScheduleIcon />,
        text:
          taskGroup.timeBlockCount !== 0
            ? `${formatSecondsTimeSimple(
                taskGroup.startTimeBlockNumber * SECONDS_IN_A_TIMEBLOCK,
              )} - ${formatSecondsTimeSimple(
                (taskGroup.startTimeBlockNumber + taskGroup.timeBlockCount) * SECONDS_IN_A_TIMEBLOCK,
              )}`
            : `${formatSecondsTimeSimple(taskGroup.startTimeBlockNumber * SECONDS_IN_A_TIMEBLOCK)}`,
        backgroundColor: "#f0f0f0",
        onClick,
      },
    ];
  }
  return [];
};

export const createRoutineLabels = (
  todayPageState: TodayPageState,
  routinesState: RoutinesState,
  taskGroup: PlanTaskGroup,
): TaskGroupLabel[] => {
  if (routinesState.initialized) {
    const routine = routinesState.routines.find((r) => r.id === taskGroup.routineId);
    if (routine) {
      return [
        {
          icon: <LoopIcon />,
          backgroundColor: colorForRoleInState(todayPageState, routine.roleId),
          tooltip: `${routine.name} is a routine`,
        },
      ];
    }
  }
  return [];
};

export const createProjectLabels = (
  todayPageState: TodayPageState,
  taskGroup: PlanTaskGroup,
  onClick: () => void,
): TaskGroupLabel[] => {
  if (taskGroup.projectId) {
    const project = todayPageState.projects[taskGroup.projectId];
    const score = projectScore(todayPageState.date, project);
    const scoreTooltip = projectScoreTooltip(todayPageState.date, project);
    return [
      {
        text: project?.name + score ?? "Unknown",
        icon: project?.showOnScoreboard ?? false ? <TrendingUpIcon /> : undefined,
        backgroundColor: project?.color ?? "#f0f0f0",
        onClick,
        tooltip: scoreTooltip,
      },
    ];
  }
  return [];
};

function projectScore(dateString: string, project: PlanProject | undefined) {
  if (project?.showOnScoreboard) {
    if (dateString > project.endDate) {
      return " - project ended";
    }
    const row = findWeeklyDataRow(dateString, project.dataForScoreboard);
    if (row) {
      return ` - ${row.completedTimeBlockCount} of ${project.weeklyPaceTimeBlockCount}`;
    }
    return ` - ${0} of ${project.weeklyPaceTimeBlockCount}`;
  }
  return "";
}
function projectScoreTooltip(dateString: string, project: PlanProject | undefined) {
  if (project?.showOnScoreboard) {
    if (dateString > project.endDate) {
      return "Project ended on " + project.endDate;
    }
    const row = findWeeklyDataRow(dateString, project.dataForScoreboard);
    if (row) {
      return `${row.completedTimeBlockCount} completed of ${
        project.weeklyPaceTimeBlockCount
      } planned before ${startOfWeekDow(project)}`;
    }
    return `${0} completed of ${project.weeklyPaceTimeBlockCount} planned before ${startOfWeekDow(project)}`;
  }
  return undefined;
}
function findWeeklyDataRow(
  dateString: string,
  data: PlanProjectDataForScoreboard[],
): PlanProjectDataForScoreboard | undefined {
  const date = parseDateSimple(dateString);
  if (!date) {
    return undefined;
  }
  for (const row of data) {
    const diff = (parseDateSimple(row.weekEndDate)?.getTime() ?? 0) - date.getTime();
    if (diff >= 0 && diff < MILLISECONDS_IN_A_WEEK) {
      return row;
    }
  }
  return undefined;
}
function startOfWeekDow(project: PlanProject) {
  return project.weekStartsDayOfWeek === 0 ? "Sunday" : "Monday";
}

export function timeBlockIsPlannedButUnused(taskGroup: PlanTaskGroup, timeBlockIndex: number) {
  return (
    taskGroup.tasks.length > 0 &&
    timeBlockIndex < taskGroup.timeBlockCount &&
    timeBlockIndex >= taskGroup.completedTimeBlockCount &&
    taskGroup.tasks.every((task) => task.completed)
  );
}
