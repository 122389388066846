import React, { ReactElement } from "react";
import { Draggable } from "react-beautiful-dnd";
import { PlanRole, PlanTaskGroup } from "../../../util/modelTypes";
import { Box, Stack } from "@mui/material";
import { TaskGroupTasks } from "./taskGroupTasks";
import { Dictionary } from "@reduxjs/toolkit";
import { TaskGroupMenuProps } from "./taskGroupActionMenu";
import { TaskEditProps } from "./plannedTask";
import { DraggableTaskGroupHeader, TaskGroupHeaderProps } from "./draggableTaskGroupHeader";

export interface TaskGroupLabel {
  text?: string;
  icon?: ReactElement;
  backgroundColor: string;
  tooltip?: string;
  onClick?: () => void;
}

interface Props extends TaskGroupMenuProps, TaskEditProps, TaskGroupHeaderProps {
  taskGroup: PlanTaskGroup;
  groupIndex: number;
  inFilter: boolean;
  editingInProgress: boolean;
  roles: Dictionary<PlanRole>;
  rolesList: PlanRole[];
  shouldBlockUI: boolean;
  setEditingInProgress: (block: boolean) => void;
  isSelected: boolean;
  onSelectTaskGroup: (taskGroupId: string) => void;
}

export const DraggableTaskGroup: React.FC<Props> = (props: Props) => {
  const { taskGroup, roles, groupIndex, inFilter } = props;

  const roleId = taskGroup.roleId;
  const roleColor = roles[roleId]?.color ?? "#000";
  const showCollapsed = taskGroup.tasks.length > 1 && taskGroup.collapsed;

  const taskGroupBoxProps = inFilter
    ? {
        borderRadius: 3,
        backgroundColor: "#fff",
        mt: 1,
        mb: showCollapsed ? 1.75 : 1,
        boxShadow: `0 1px 5px ${roles[roleId]?.color ?? "#000"}}`,
      }
    : {};

  return (
    <div key={taskGroup.id} data-testid="TaskGroup">
      <Draggable key={taskGroup.id} draggableId={taskGroup.id} index={groupIndex}>
        {(provided, snapshot) => (
          <Box sx={{ position: "relative" }}>
            <Box
              id={`task-group-drag-box-${groupIndex}`}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              sx={taskGroupBoxProps}
            >
              {inFilter && (
                <Stack sx={{ alignItems: "flex-start" }}>
                  <DraggableTaskGroupHeader {...props} isDragging={snapshot.isDragging} />
                  <TaskGroupTasks {...props} tasks={taskGroup.tasks} />
                  <TaskGroupCollapseBorder showCollapsed={showCollapsed} color={roleColor} />
                </Stack>
              )}
            </Box>
          </Box>
        )}
      </Draggable>
    </div>
  );
};

interface TaskGroupCollapseBorderProps {
  showCollapsed: boolean;
  color: string;
}

const TaskGroupCollapseBorder: React.FC<TaskGroupCollapseBorderProps> = ({ showCollapsed, color }) => {
  return showCollapsed ? (
    <>
      {[0, 1].map((i) => (
        <Box
          key={i}
          sx={{
            justifySelf: "center",
            width: "100%",
            top: -1,
            left: -1,
            position: "absolute",
            bottom: -3.5 - i * 3.5,
            zIndex: -100 - i,
            borderRadius: 3,
            backgroundColor: "#fff",
            boxShadow: `0 2px 2px ${color}}`,
          }}
        />
      ))}
    </>
  ) : (
    <></>
  );
};
