import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React from "react";
import { range } from "../util/arrayUtils";
import { SECONDS_IN_A_TIMEBLOCK, formatSecondsTimeSimple } from "../util/dateUtils";

interface Props {
  label: string;
  timeBlock: number;
  setTimeBlock: (timeBlock: number) => void;
  isEndTime?: boolean;
}

export const TimeSelect: React.FC<Props> = ({ label, timeBlock, setTimeBlock, isEndTime }) => {
  const handleChange = (event: SelectChangeEvent) => {
    setTimeBlock(parseInt(event.target.value));
  };
  const idSafeLabel = label.replaceAll(" ", "");
  const offset = isEndTime ?? false ? 1 : 0;
  return (
    <FormControl>
      <InputLabel id={`time-select-label-${idSafeLabel}`}>{label}</InputLabel>
      <Select
        labelId={`time-select-label-${idSafeLabel}`}
        id={`time-select-${idSafeLabel}`}
        value={timeBlock.toString()}
        label={label}
        onChange={handleChange}
        data-testid="TimeSelect"
      >
        {range(48).map((b) => (
          <MenuItem key={b} value={b.toString()} data-testid="TimeSelectMenuItem">
            {formatSecondsTimeSimple((b + offset) * SECONDS_IN_A_TIMEBLOCK)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
