import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type AdminStats = {
  __typename?: 'AdminStats';
  activeUsers: Array<WeekStats>;
  id: Scalars['ID']['output'];
  plans: Array<WeekStats>;
  users: Array<WeekStats>;
};

export type DailyPlan = {
  __typename?: 'DailyPlan';
  date: Scalars['String']['output'];
  endTimeBlockNumber: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  maxTimeBlockCount: Scalars['Int']['output'];
  plannedBlocks: Array<PlannedBlock>;
  startTimeBlockNumber: Scalars['Int']['output'];
  taskGroups?: Maybe<Array<DailyPlanTaskGroup>>;
  user: User;
};

export type DailyPlanTaskGroup = {
  __typename?: 'DailyPlanTaskGroup';
  collapsed: Scalars['Boolean']['output'];
  completedTimeBlockCount: Scalars['Int']['output'];
  dailyPlan: DailyPlan;
  id: Scalars['ID']['output'];
  isGrayTime: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  order: Scalars['Float']['output'];
  project?: Maybe<Project>;
  role: Role;
  routine?: Maybe<Routine>;
  startTimeBlockNumber?: Maybe<Scalars['Int']['output']>;
  tasks: Array<Task>;
  timeBlockCount: Scalars['Int']['output'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String']['output'];
  isSiteAdmin: Scalars['Boolean']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addNewTaskToGroup: SuccessResponse;
  addNewTaskToPlan: SuccessResponse;
  addRoutineToPlan: SuccessResponse;
  addTaskToPlan: SuccessResponse;
  addTimeCardToPlan: SuccessResponse;
  addUnplannedTask: SuccessResponse;
  archiveUserRoutine: SuccessResponse;
  changePassword: LoginResponse;
  createUserDailyPlanForDate: SuccessResponse;
  createUserProject: SuccessResponse;
  deletePlannedTask: SuccessResponse;
  deleteTimeCardFromPlan: SuccessResponse;
  deleteUnplannedTask: SuccessResponse;
  inviteRequestedSiteUser: SuccessResponse;
  inviteSiteUser: SuccessResponse;
  login: LoginResponse;
  makeRoutineFromTaskGroup: SuccessResponse;
  moveIncompleteTasksOfTaskGroupToDate: SuccessResponse;
  moveUserTaskToGroup: SuccessResponse;
  registerWithInvitation: LoginResponse;
  requestInviteForSiteUser: SuccessResponse;
  resendInvitation: SuccessResponse;
  setPlanEndTimeBlockNumber: SuccessResponse;
  setPlanStartTimeBlockNumber: SuccessResponse;
  setUserPlanBlock: SuccessResponse;
  setUserPlanCollapsedForTaskGroups: SuccessResponse;
  setUserRoutineDaysOfTheWeek: SuccessResponse;
  setUserSettings: SuccessResponse;
  setUserTaskCompleted: SuccessResponse;
  setUserTaskGroupCollapsed: SuccessResponse;
  setUserTaskGroupCompletedTimeBlockCount: SuccessResponse;
  setUserTaskGroupGrayTime: SuccessResponse;
  setUserTaskGroupName: SuccessResponse;
  setUserTaskGroupOrder: SuccessResponse;
  setUserTaskGroupProject: SuccessResponse;
  setUserTaskGroupRole: SuccessResponse;
  setUserTaskGroupStartTimeBlockNumber: SuccessResponse;
  setUserTaskGroupTimeBlockCount: SuccessResponse;
  setUserTaskName: SuccessResponse;
  setUserTaskOrder: SuccessResponse;
  setUserTaskRole: SuccessResponse;
  unplanTask: SuccessResponse;
  unsetUserTaskGroupProject: SuccessResponse;
  updateRoutineFromTaskGroup: SuccessResponse;
  updateUserProject: SuccessResponse;
};


export type MutationAddNewTaskToGroupArgs = {
  dailyPlanTaskGroupId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Float']['input'];
  taskId: Scalars['String']['input'];
};


export type MutationAddNewTaskToPlanArgs = {
  dailyPlanId: Scalars['String']['input'];
  date: Scalars['String']['input'];
  isGrayTime: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Float']['input'];
  roleId: Scalars['String']['input'];
  taskGroupId: Scalars['String']['input'];
  taskGroupOrder: Scalars['Float']['input'];
  taskId: Scalars['String']['input'];
};


export type MutationAddRoutineToPlanArgs = {
  dailyPlanId: Scalars['String']['input'];
  date: Scalars['String']['input'];
  routineId: Scalars['String']['input'];
  taskGroupId: Scalars['String']['input'];
  taskGroupOrder: Scalars['Float']['input'];
};


export type MutationAddTaskToPlanArgs = {
  dailyPlanId: Scalars['String']['input'];
  date: Scalars['String']['input'];
  isGrayTime: Scalars['Boolean']['input'];
  taskGroupId: Scalars['String']['input'];
  taskGroupOrder: Scalars['Float']['input'];
  taskId: Scalars['String']['input'];
};


export type MutationAddTimeCardToPlanArgs = {
  dailyPlanId: Scalars['String']['input'];
  dailyPlanTimeCardId: Scalars['String']['input'];
  date: Scalars['String']['input'];
  isGrayTime: Scalars['Boolean']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Float']['input'];
  roleId: Scalars['String']['input'];
};


export type MutationAddUnplannedTaskArgs = {
  name: Scalars['String']['input'];
  order: Scalars['Float']['input'];
  roleId: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
};


export type MutationArchiveUserRoutineArgs = {
  routineId: Scalars['String']['input'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};


export type MutationCreateUserDailyPlanForDateArgs = {
  dailyPlanId: Scalars['String']['input'];
  date: Scalars['String']['input'];
};


export type MutationCreateUserProjectArgs = {
  color: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
  showOnScoreboard: Scalars['Boolean']['input'];
  startDate: Scalars['String']['input'];
  weekStartsDayOfWeek: Scalars['Int']['input'];
  weeklyPaceTimeBlockCount: Scalars['Int']['input'];
};


export type MutationDeletePlannedTaskArgs = {
  taskId: Scalars['String']['input'];
};


export type MutationDeleteTimeCardFromPlanArgs = {
  dailyPlanTimeCardId: Scalars['String']['input'];
};


export type MutationDeleteUnplannedTaskArgs = {
  taskId: Scalars['String']['input'];
};


export type MutationInviteRequestedSiteUserArgs = {
  userId: Scalars['String']['input'];
};


export type MutationInviteSiteUserArgs = {
  displayName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  inviteCode: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationMakeRoutineFromTaskGroupArgs = {
  dailyPlanTaskGroupId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  routineId: Scalars['String']['input'];
};


export type MutationMoveIncompleteTasksOfTaskGroupToDateArgs = {
  dailyPlanTaskGroupId: Scalars['String']['input'];
  date: Scalars['String']['input'];
};


export type MutationMoveUserTaskToGroupArgs = {
  dailyPlanTaskGroupId: Scalars['String']['input'];
  groupOrder: Scalars['Float']['input'];
  planId: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
  taskOrder: Scalars['Float']['input'];
};


export type MutationRegisterWithInvitationArgs = {
  inviteCode: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationRequestInviteForSiteUserArgs = {
  displayName: Scalars['String']['input'];
  email: Scalars['String']['input'];
};


export type MutationResendInvitationArgs = {
  userId: Scalars['String']['input'];
};


export type MutationSetPlanEndTimeBlockNumberArgs = {
  dailyPlanId: Scalars['String']['input'];
  date: Scalars['String']['input'];
  endTimeBlockNumber: Scalars['Int']['input'];
};


export type MutationSetPlanStartTimeBlockNumberArgs = {
  dailyPlanId: Scalars['String']['input'];
  date: Scalars['String']['input'];
  startTimeBlockNumber: Scalars['Int']['input'];
};


export type MutationSetUserPlanBlockArgs = {
  blockNumber: Scalars['Int']['input'];
  dailyPlanId: Scalars['String']['input'];
  date: Scalars['String']['input'];
  plannedBlockType?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetUserPlanCollapsedForTaskGroupsArgs = {
  collapsed: Scalars['Boolean']['input'];
  dailyPlanId: Scalars['String']['input'];
};


export type MutationSetUserRoutineDaysOfTheWeekArgs = {
  daysOfTheWeek: Scalars['Int']['input'];
  routineId: Scalars['String']['input'];
};


export type MutationSetUserSettingsArgs = {
  defaultEndTimeBlockNumber: Scalars['Int']['input'];
  defaultStartTimeBlockNumber: Scalars['Int']['input'];
  grayIsDefault: Scalars['Boolean']['input'];
};


export type MutationSetUserTaskCompletedArgs = {
  completed: Scalars['Boolean']['input'];
  taskId: Scalars['String']['input'];
};


export type MutationSetUserTaskGroupCollapsedArgs = {
  collapsed: Scalars['Boolean']['input'];
  dailyPlanTaskGroupId: Scalars['String']['input'];
};


export type MutationSetUserTaskGroupCompletedTimeBlockCountArgs = {
  completedTimeBlockCount: Scalars['Int']['input'];
  dailyPlanTaskGroupId: Scalars['String']['input'];
};


export type MutationSetUserTaskGroupGrayTimeArgs = {
  dailyPlanTaskGroupId: Scalars['String']['input'];
  isGrayTime: Scalars['Boolean']['input'];
};


export type MutationSetUserTaskGroupNameArgs = {
  dailyPlanTaskGroupId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetUserTaskGroupOrderArgs = {
  dailyPlanTaskGroupId: Scalars['String']['input'];
  order: Scalars['Float']['input'];
};


export type MutationSetUserTaskGroupProjectArgs = {
  dailyPlanTaskGroupId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};


export type MutationSetUserTaskGroupRoleArgs = {
  dailyPlanTaskGroupId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
};


export type MutationSetUserTaskGroupStartTimeBlockNumberArgs = {
  dailyPlanTaskGroupId: Scalars['String']['input'];
  startTimeBlockNumber?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSetUserTaskGroupTimeBlockCountArgs = {
  dailyPlanTaskGroupId: Scalars['String']['input'];
  timeBlockCount: Scalars['Int']['input'];
};


export type MutationSetUserTaskNameArgs = {
  name: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
};


export type MutationSetUserTaskOrderArgs = {
  order: Scalars['Float']['input'];
  taskId: Scalars['String']['input'];
};


export type MutationSetUserTaskRoleArgs = {
  roleId: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
};


export type MutationUnplanTaskArgs = {
  taskId: Scalars['String']['input'];
};


export type MutationUnsetUserTaskGroupProjectArgs = {
  dailyPlanTaskGroupId: Scalars['String']['input'];
};


export type MutationUpdateRoutineFromTaskGroupArgs = {
  dailyPlanTaskGroupId: Scalars['String']['input'];
  routineId: Scalars['String']['input'];
};


export type MutationUpdateUserProjectArgs = {
  color: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  showOnScoreboard: Scalars['Boolean']['input'];
  startDate: Scalars['String']['input'];
  weekStartsDayOfWeek: Scalars['Int']['input'];
  weeklyPaceTimeBlockCount: Scalars['Int']['input'];
};

export type PlanAndCompletedBlockCountResponse = {
  __typename?: 'PlanAndCompletedBlockCountResponse';
  capacity: Scalars['Float']['output'];
  completedTimeBlockCount: Scalars['Float']['output'];
  date: Scalars['String']['output'];
  grayCompletedTimeBlockCount: Scalars['Float']['output'];
  planId: Scalars['String']['output'];
  roleId: Scalars['String']['output'];
};

export type PlannedBlock = {
  __typename?: 'PlannedBlock';
  blockNumber: Scalars['Int']['output'];
  dailyPlan: DailyPlan;
  id: Scalars['ID']['output'];
  role: Role;
  type: Scalars['String']['output'];
};

export type Project = {
  __typename?: 'Project';
  color: Scalars['String']['output'];
  dataForScoreboard: Array<ProjectDataForScoreboardResponse>;
  endDate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  role: Role;
  routines: Array<Routine>;
  showOnScoreboard: Scalars['Boolean']['output'];
  startDate: Scalars['String']['output'];
  taskGroups: Array<DailyPlanTaskGroup>;
  user: User;
  weekStartsDayOfWeek: Scalars['Int']['output'];
  weeklyPaceTimeBlockCount: Scalars['Int']['output'];
};

export type ProjectDataForScoreboardResponse = {
  __typename?: 'ProjectDataForScoreboardResponse';
  completedTimeBlockCount: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  weekEndDate: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  adminStats: AdminStats;
  inviteRequestedUsers: Array<User>;
  invitedUsers: Array<User>;
  userAccount: User;
  userDailyPlanForDate?: Maybe<DailyPlan>;
  userForInvite: User;
  userProject: Project;
  userProjects: Array<Project>;
  userRoles: Array<Role>;
  userRoutines: Array<Routine>;
  userTaskGroups: Array<DailyPlanTaskGroup>;
  userTasks: Array<Task>;
  userUnplannedTasks: Array<Task>;
  weekPlanTimeBlockCounts: Array<PlanAndCompletedBlockCountResponse>;
};


export type QueryUserDailyPlanForDateArgs = {
  date: Scalars['String']['input'];
};


export type QueryUserForInviteArgs = {
  inviteCode: Scalars['String']['input'];
};


export type QueryUserProjectArgs = {
  projectId: Scalars['String']['input'];
};


export type QueryUserTaskGroupsArgs = {
  dailyPlanId: Scalars['String']['input'];
};


export type QueryWeekPlanTimeBlockCountsArgs = {
  date: Scalars['String']['input'];
};

export type Role = {
  __typename?: 'Role';
  color: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Float']['output'];
  plannedBlocks: Array<PlannedBlock>;
  user: User;
};

export type Routine = {
  __typename?: 'Routine';
  archived: Scalars['Boolean']['output'];
  daysOfTheWeek: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isGrayTime: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  project?: Maybe<Project>;
  role: Role;
  taskGroups: Array<DailyPlanTaskGroup>;
  tasks: Array<RoutineTask>;
  timeBlockCount: Scalars['Int']['output'];
  user: User;
};

export type RoutineTask = {
  __typename?: 'RoutineTask';
  createdDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Float']['output'];
  routine: Routine;
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  error: Scalars['String']['output'];
  idInserted?: Maybe<Scalars['String']['output']>;
  idsInserted?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type Task = {
  __typename?: 'Task';
  completed: Scalars['Boolean']['output'];
  createdDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Float']['output'];
  role: Role;
  taskGroup?: Maybe<DailyPlanTaskGroup>;
  user: User;
};

export type User = {
  __typename?: 'User';
  createdDate: Scalars['DateTime']['output'];
  defaultEndTimeBlockNumber: Scalars['Int']['output'];
  defaultStartTimeBlockNumber: Scalars['Int']['output'];
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  grayIsDefault: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  inviteCode?: Maybe<Scalars['String']['output']>;
  lastLoginDate?: Maybe<Scalars['DateTime']['output']>;
  projects: Array<Project>;
  roles: Array<Role>;
};

export type WeekStats = {
  __typename?: 'WeekStats';
  count: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  weekEndDateString: Scalars['String']['output'];
};

export type AdminStatsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminStatsPageQuery = { __typename?: 'Query', adminStats: { __typename?: 'AdminStats', id: string, plans: Array<{ __typename?: 'WeekStats', id: string, count: number, weekEndDateString: string }>, users: Array<{ __typename?: 'WeekStats', id: string, count: number, weekEndDateString: string }>, activeUsers: Array<{ __typename?: 'WeekStats', id: string, count: number, weekEndDateString: string }> } };

export type BacklogQueryVariables = Exact<{ [key: string]: never; }>;


export type BacklogQuery = { __typename?: 'Query', userUnplannedTasks: Array<{ __typename?: 'Task', id: string, name: string, completed: boolean, order: number, taskGroup?: { __typename?: 'DailyPlanTaskGroup', id: string } | null, role: { __typename?: 'Role', id: string } }>, userAccount: { __typename?: 'User', id: string, roles: Array<{ __typename?: 'Role', id: string, name: string, color: string, order: number }> } };

export type UserDailyPlanQueryVariables = Exact<{
  date: Scalars['String']['input'];
}>;


export type UserDailyPlanQuery = { __typename?: 'Query', userDailyPlanForDate?: { __typename?: 'DailyPlan', id: string, maxTimeBlockCount: number } | null };

export type CreateUserDailyPlanForDateMutationVariables = Exact<{
  dailyPlanId: Scalars['String']['input'];
  date: Scalars['String']['input'];
}>;


export type CreateUserDailyPlanForDateMutation = { __typename?: 'Mutation', createUserDailyPlanForDate: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type AddTaskToPlanMutationVariables = Exact<{
  dailyPlanId: Scalars['String']['input'];
  date: Scalars['String']['input'];
  taskGroupId: Scalars['String']['input'];
  taskGroupOrder: Scalars['Float']['input'];
  taskId: Scalars['String']['input'];
  isGrayTime: Scalars['Boolean']['input'];
}>;


export type AddTaskToPlanMutation = { __typename?: 'Mutation', addTaskToPlan: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type AddNewTaskToPlanMutationVariables = Exact<{
  dailyPlanId: Scalars['String']['input'];
  date: Scalars['String']['input'];
  taskGroupId: Scalars['String']['input'];
  taskGroupOrder: Scalars['Float']['input'];
  taskId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Float']['input'];
  isGrayTime: Scalars['Boolean']['input'];
}>;


export type AddNewTaskToPlanMutation = { __typename?: 'Mutation', addNewTaskToPlan: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type SetPlanStartTimeBlockNumberMutationVariables = Exact<{
  dailyPlanId: Scalars['String']['input'];
  date: Scalars['String']['input'];
  startTimeBlockNumber: Scalars['Int']['input'];
}>;


export type SetPlanStartTimeBlockNumberMutation = { __typename?: 'Mutation', setPlanStartTimeBlockNumber: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type SetPlanEndTimeBlockNumberMutationVariables = Exact<{
  dailyPlanId: Scalars['String']['input'];
  date: Scalars['String']['input'];
  endTimeBlockNumber: Scalars['Int']['input'];
}>;


export type SetPlanEndTimeBlockNumberMutation = { __typename?: 'Mutation', setPlanEndTimeBlockNumber: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type SetUserPlanBlockMutationVariables = Exact<{
  dailyPlanId: Scalars['String']['input'];
  date: Scalars['String']['input'];
  blockNumber: Scalars['Int']['input'];
  roleId?: InputMaybe<Scalars['String']['input']>;
  plannedBlockType?: InputMaybe<Scalars['String']['input']>;
}>;


export type SetUserPlanBlockMutation = { __typename?: 'Mutation', setUserPlanBlock: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type SetUserPlanCollapsedForTaskGroupsMutationVariables = Exact<{
  dailyPlanId: Scalars['String']['input'];
  collapsed: Scalars['Boolean']['input'];
}>;


export type SetUserPlanCollapsedForTaskGroupsMutation = { __typename?: 'Mutation', setUserPlanCollapsedForTaskGroups: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type SetUserTaskGroupRoleMutationVariables = Exact<{
  dailyPlanTaskGroupId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
}>;


export type SetUserTaskGroupRoleMutation = { __typename?: 'Mutation', setUserTaskGroupRole: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type SetUserTaskGroupTimeBlockCountMutationVariables = Exact<{
  dailyPlanTaskGroupId: Scalars['String']['input'];
  timeBlockCount: Scalars['Int']['input'];
}>;


export type SetUserTaskGroupTimeBlockCountMutation = { __typename?: 'Mutation', setUserTaskGroupTimeBlockCount: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type SetUserTaskGroupCompletedTimeBlockCountMutationVariables = Exact<{
  dailyPlanTaskGroupId: Scalars['String']['input'];
  completedTimeBlockCount: Scalars['Int']['input'];
}>;


export type SetUserTaskGroupCompletedTimeBlockCountMutation = { __typename?: 'Mutation', setUserTaskGroupCompletedTimeBlockCount: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type SetUserTaskGroupOrderMutationVariables = Exact<{
  dailyPlanTaskGroupId: Scalars['String']['input'];
  order: Scalars['Float']['input'];
}>;


export type SetUserTaskGroupOrderMutation = { __typename?: 'Mutation', setUserTaskGroupOrder: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type SetUserTaskGroupCollapsedMutationVariables = Exact<{
  dailyPlanTaskGroupId: Scalars['String']['input'];
  collapsed: Scalars['Boolean']['input'];
}>;


export type SetUserTaskGroupCollapsedMutation = { __typename?: 'Mutation', setUserTaskGroupCollapsed: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type SetUserTaskGroupGrayTimeMutationVariables = Exact<{
  dailyPlanTaskGroupId: Scalars['String']['input'];
  isGrayTime: Scalars['Boolean']['input'];
}>;


export type SetUserTaskGroupGrayTimeMutation = { __typename?: 'Mutation', setUserTaskGroupGrayTime: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type SetUserTaskGroupNameMutationVariables = Exact<{
  dailyPlanTaskGroupId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type SetUserTaskGroupNameMutation = { __typename?: 'Mutation', setUserTaskGroupName: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type SetUserTaskGroupStartTimeBlockNumberMutationVariables = Exact<{
  dailyPlanTaskGroupId: Scalars['String']['input'];
  startTimeBlockNumber?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SetUserTaskGroupStartTimeBlockNumberMutation = { __typename?: 'Mutation', setUserTaskGroupStartTimeBlockNumber: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type AddNewTaskToGroupMutationVariables = Exact<{
  taskId: Scalars['String']['input'];
  dailyPlanTaskGroupId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Float']['input'];
}>;


export type AddNewTaskToGroupMutation = { __typename?: 'Mutation', addNewTaskToGroup: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type AddTimeCardToPlanMutationVariables = Exact<{
  dailyPlanId: Scalars['String']['input'];
  date: Scalars['String']['input'];
  dailyPlanTimeCardId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Float']['input'];
  roleId: Scalars['String']['input'];
  isGrayTime: Scalars['Boolean']['input'];
}>;


export type AddTimeCardToPlanMutation = { __typename?: 'Mutation', addTimeCardToPlan: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type DeleteTimeCardFromPlanMutationVariables = Exact<{
  dailyPlanTimeCardId: Scalars['String']['input'];
}>;


export type DeleteTimeCardFromPlanMutation = { __typename?: 'Mutation', deleteTimeCardFromPlan: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type MoveUserTaskToGroupMutationVariables = Exact<{
  taskId: Scalars['String']['input'];
  planId: Scalars['String']['input'];
  dailyPlanTaskGroupId: Scalars['String']['input'];
  groupOrder: Scalars['Float']['input'];
  taskOrder: Scalars['Float']['input'];
}>;


export type MoveUserTaskToGroupMutation = { __typename?: 'Mutation', moveUserTaskToGroup: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type MakeRoutineFromTaskGroupMutationVariables = Exact<{
  routineId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  dailyPlanTaskGroupId: Scalars['String']['input'];
}>;


export type MakeRoutineFromTaskGroupMutation = { __typename?: 'Mutation', makeRoutineFromTaskGroup: { __typename?: 'SuccessResponse', success: boolean, error: string, idsInserted?: Array<string> | null } };

export type UpdateRoutineFromTaskGroupMutationVariables = Exact<{
  routineId: Scalars['String']['input'];
  dailyPlanTaskGroupId: Scalars['String']['input'];
}>;


export type UpdateRoutineFromTaskGroupMutation = { __typename?: 'Mutation', updateRoutineFromTaskGroup: { __typename?: 'SuccessResponse', success: boolean, error: string, idsInserted?: Array<string> | null } };

export type MoveIncompleteTasksOfTaskGroupToDateMutationVariables = Exact<{
  dailyPlanTaskGroupId: Scalars['String']['input'];
  date: Scalars['String']['input'];
}>;


export type MoveIncompleteTasksOfTaskGroupToDateMutation = { __typename?: 'Mutation', moveIncompleteTasksOfTaskGroupToDate: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type SetUserTaskGroupProjectMutationVariables = Exact<{
  dailyPlanTaskGroupId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
}>;


export type SetUserTaskGroupProjectMutation = { __typename?: 'Mutation', setUserTaskGroupProject: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type UnsetUserTaskGroupProjectMutationVariables = Exact<{
  dailyPlanTaskGroupId: Scalars['String']['input'];
}>;


export type UnsetUserTaskGroupProjectMutation = { __typename?: 'Mutation', unsetUserTaskGroupProject: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type CreateUserProjectMutationVariables = Exact<{
  projectId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  color: Scalars['String']['input'];
  showOnScoreboard: Scalars['Boolean']['input'];
  startDate: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
  weeklyPaceTimeBlockCount: Scalars['Int']['input'];
  weekStartsDayOfWeek: Scalars['Int']['input'];
  roleId: Scalars['String']['input'];
}>;


export type CreateUserProjectMutation = { __typename?: 'Mutation', createUserProject: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type UpdateUserProjectMutationVariables = Exact<{
  projectId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  color: Scalars['String']['input'];
  showOnScoreboard: Scalars['Boolean']['input'];
  startDate: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
  weeklyPaceTimeBlockCount: Scalars['Int']['input'];
  weekStartsDayOfWeek: Scalars['Int']['input'];
}>;


export type UpdateUserProjectMutation = { __typename?: 'Mutation', updateUserProject: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type GetProjectScoreboardQueryVariables = Exact<{
  projectId: Scalars['String']['input'];
}>;


export type GetProjectScoreboardQuery = { __typename?: 'Query', userProject: { __typename?: 'Project', id: string, dataForScoreboard: Array<{ __typename?: 'ProjectDataForScoreboardResponse', id: string, weekEndDate: string, completedTimeBlockCount: number }> } };

export type RoutinesQueryVariables = Exact<{ [key: string]: never; }>;


export type RoutinesQuery = { __typename?: 'Query', userRoutines: Array<{ __typename?: 'Routine', id: string, name: string, timeBlockCount: number, archived: boolean, isGrayTime: boolean, daysOfTheWeek: number, project?: { __typename?: 'Project', id: string, name: string } | null, role: { __typename?: 'Role', id: string }, tasks: Array<{ __typename?: 'RoutineTask', id: string, name: string, order: number }> }>, userAccount: { __typename?: 'User', id: string, roles: Array<{ __typename?: 'Role', id: string, name: string, color: string, order: number }> } };

export type AddRoutineToPlanMutationVariables = Exact<{
  routineId: Scalars['String']['input'];
  dailyPlanId: Scalars['String']['input'];
  date: Scalars['String']['input'];
  taskGroupId: Scalars['String']['input'];
  taskGroupOrder: Scalars['Float']['input'];
}>;


export type AddRoutineToPlanMutation = { __typename?: 'Mutation', addRoutineToPlan: { __typename?: 'SuccessResponse', success: boolean, error: string, idsInserted?: Array<string> | null } };

export type ArchiveUserRoutineMutationVariables = Exact<{
  routineId: Scalars['String']['input'];
}>;


export type ArchiveUserRoutineMutation = { __typename?: 'Mutation', archiveUserRoutine: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type SetUserRoutineDaysOfTheWeekMutationVariables = Exact<{
  routineId: Scalars['String']['input'];
  daysOfTheWeek: Scalars['Int']['input'];
}>;


export type SetUserRoutineDaysOfTheWeekMutation = { __typename?: 'Mutation', setUserRoutineDaysOfTheWeek: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type RoutinesPageQueryVariables = Exact<{ [key: string]: never; }>;


export type RoutinesPageQuery = { __typename?: 'Query', userRoutines: Array<{ __typename?: 'Routine', id: string, name: string, timeBlockCount: number, role: { __typename?: 'Role', id: string }, tasks: Array<{ __typename?: 'RoutineTask', id: string, name: string, order: number }> }>, userAccount: { __typename?: 'User', id: string, roles: Array<{ __typename?: 'Role', id: string, name: string, color: string, order: number }> } };

export type SettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsQuery = { __typename?: 'Query', userAccount: { __typename?: 'User', id: string, grayIsDefault: boolean, defaultStartTimeBlockNumber: number, defaultEndTimeBlockNumber: number } };

export type SetUserSettingsMutationVariables = Exact<{
  grayIsDefault: Scalars['Boolean']['input'];
  defaultStartTimeBlockNumber: Scalars['Int']['input'];
  defaultEndTimeBlockNumber: Scalars['Int']['input'];
}>;


export type SetUserSettingsMutation = { __typename?: 'Mutation', setUserSettings: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type UserTasksQueryVariables = Exact<{ [key: string]: never; }>;


export type UserTasksQuery = { __typename?: 'Query', userTasks: Array<{ __typename?: 'Task', id: string, name: string, completed: boolean, order: number }> };

export type SetUserTaskCompletedMutationVariables = Exact<{
  taskId: Scalars['String']['input'];
  completed: Scalars['Boolean']['input'];
}>;


export type SetUserTaskCompletedMutation = { __typename?: 'Mutation', setUserTaskCompleted: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type UnplanTaskMutationVariables = Exact<{
  taskId: Scalars['String']['input'];
}>;


export type UnplanTaskMutation = { __typename?: 'Mutation', unplanTask: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type AddUnplannedTaskMutationVariables = Exact<{
  taskId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Float']['input'];
}>;


export type AddUnplannedTaskMutation = { __typename?: 'Mutation', addUnplannedTask: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type DeleteUnplannedTaskMutationVariables = Exact<{
  taskId: Scalars['String']['input'];
}>;


export type DeleteUnplannedTaskMutation = { __typename?: 'Mutation', deleteUnplannedTask: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type SetUserTaskOrderMutationVariables = Exact<{
  taskId: Scalars['String']['input'];
  order: Scalars['Float']['input'];
}>;


export type SetUserTaskOrderMutation = { __typename?: 'Mutation', setUserTaskOrder: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type SetUserTaskNameMutationVariables = Exact<{
  taskId: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;


export type SetUserTaskNameMutation = { __typename?: 'Mutation', setUserTaskName: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type SetUserTaskRoleMutationVariables = Exact<{
  taskId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
}>;


export type SetUserTaskRoleMutation = { __typename?: 'Mutation', setUserTaskRole: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type DeletePlannedTaskMutationVariables = Exact<{
  taskId: Scalars['String']['input'];
}>;


export type DeletePlannedTaskMutation = { __typename?: 'Mutation', deletePlannedTask: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type TodayPageQueryVariables = Exact<{
  date: Scalars['String']['input'];
}>;


export type TodayPageQuery = { __typename?: 'Query', userDailyPlanForDate?: { __typename?: 'DailyPlan', id: string, maxTimeBlockCount: number, startTimeBlockNumber: number, endTimeBlockNumber: number, taskGroups?: Array<{ __typename?: 'DailyPlanTaskGroup', id: string, name?: string | null, timeBlockCount: number, completedTimeBlockCount: number, startTimeBlockNumber?: number | null, order: number, collapsed: boolean, isGrayTime: boolean, role: { __typename?: 'Role', id: string }, tasks: Array<{ __typename?: 'Task', id: string, name: string, completed: boolean, order: number }>, routine?: { __typename?: 'Routine', id: string, name: string } | null, project?: { __typename?: 'Project', id: string, name: string } | null }> | null, plannedBlocks: Array<{ __typename?: 'PlannedBlock', id: string, type: string, blockNumber: number, role: { __typename?: 'Role', id: string } }> } | null, weekPlanTimeBlockCounts: Array<{ __typename?: 'PlanAndCompletedBlockCountResponse', planId: string, date: string, roleId: string, capacity: number, completedTimeBlockCount: number, grayCompletedTimeBlockCount: number }>, userAccount: { __typename?: 'User', id: string, roles: Array<{ __typename?: 'Role', id: string, name: string, color: string, order: number }>, projects: Array<{ __typename?: 'Project', id: string, name: string, color: string, showOnScoreboard: boolean, startDate: string, endDate: string, weeklyPaceTimeBlockCount: number, weekStartsDayOfWeek: number, role: { __typename?: 'Role', id: string }, dataForScoreboard: Array<{ __typename?: 'ProjectDataForScoreboardResponse', id: string, weekEndDate: string, completedTimeBlockCount: number }> }> } };

export type UserAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type UserAccountQuery = { __typename?: 'Query', userAccount: { __typename?: 'User', id: string, displayName: string } };

export type UserForInviteQueryVariables = Exact<{
  inviteCode: Scalars['String']['input'];
}>;


export type UserForInviteQuery = { __typename?: 'Query', userForInvite: { __typename?: 'User', id: string, email: string, displayName: string } };

export type InvitationsQueryVariables = Exact<{ [key: string]: never; }>;


export type InvitationsQuery = { __typename?: 'Query', invitedUsers: Array<{ __typename?: 'User', id: string, email: string, displayName: string, createdDate: any }>, inviteRequestedUsers: Array<{ __typename?: 'User', id: string, email: string, displayName: string, createdDate: any }> };

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', accessToken: string, isSiteAdmin: boolean } };

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword: { __typename?: 'LoginResponse', accessToken: string, isSiteAdmin: boolean } };

export type InviteSiteUserMutationVariables = Exact<{
  email: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  inviteCode: Scalars['String']['input'];
}>;


export type InviteSiteUserMutation = { __typename?: 'Mutation', inviteSiteUser: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type InviteRequestedSiteUserMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type InviteRequestedSiteUserMutation = { __typename?: 'Mutation', inviteRequestedSiteUser: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type RequestInviteForSiteUserMutationVariables = Exact<{
  email: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
}>;


export type RequestInviteForSiteUserMutation = { __typename?: 'Mutation', requestInviteForSiteUser: { __typename?: 'SuccessResponse', success: boolean, error: string } };

export type RegisterWithInvitationMutationVariables = Exact<{
  inviteCode: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type RegisterWithInvitationMutation = { __typename?: 'Mutation', registerWithInvitation: { __typename?: 'LoginResponse', accessToken: string, isSiteAdmin: boolean } };

export type ResendInvitationMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type ResendInvitationMutation = { __typename?: 'Mutation', resendInvitation: { __typename?: 'SuccessResponse', success: boolean, error: string } };


export const AdminStatsPageDocument = gql`
    query AdminStatsPage {
  adminStats {
    id
    plans {
      id
      count
      weekEndDateString
    }
    users {
      id
      count
      weekEndDateString
    }
    activeUsers {
      id
      count
      weekEndDateString
    }
  }
}
    `;

/**
 * __useAdminStatsPageQuery__
 *
 * To run a query within a React component, call `useAdminStatsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminStatsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminStatsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminStatsPageQuery(baseOptions?: Apollo.QueryHookOptions<AdminStatsPageQuery, AdminStatsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminStatsPageQuery, AdminStatsPageQueryVariables>(AdminStatsPageDocument, options);
      }
export function useAdminStatsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminStatsPageQuery, AdminStatsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminStatsPageQuery, AdminStatsPageQueryVariables>(AdminStatsPageDocument, options);
        }
export type AdminStatsPageQueryHookResult = ReturnType<typeof useAdminStatsPageQuery>;
export type AdminStatsPageLazyQueryHookResult = ReturnType<typeof useAdminStatsPageLazyQuery>;
export type AdminStatsPageQueryResult = Apollo.QueryResult<AdminStatsPageQuery, AdminStatsPageQueryVariables>;
export const BacklogDocument = gql`
    query Backlog {
  userUnplannedTasks {
    id
    name
    completed
    order
    taskGroup {
      id
    }
    role {
      id
    }
  }
  userAccount {
    id
    roles {
      id
      name
      color
      order
    }
  }
}
    `;

/**
 * __useBacklogQuery__
 *
 * To run a query within a React component, call `useBacklogQuery` and pass it any options that fit your needs.
 * When your component renders, `useBacklogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBacklogQuery({
 *   variables: {
 *   },
 * });
 */
export function useBacklogQuery(baseOptions?: Apollo.QueryHookOptions<BacklogQuery, BacklogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BacklogQuery, BacklogQueryVariables>(BacklogDocument, options);
      }
export function useBacklogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BacklogQuery, BacklogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BacklogQuery, BacklogQueryVariables>(BacklogDocument, options);
        }
export type BacklogQueryHookResult = ReturnType<typeof useBacklogQuery>;
export type BacklogLazyQueryHookResult = ReturnType<typeof useBacklogLazyQuery>;
export type BacklogQueryResult = Apollo.QueryResult<BacklogQuery, BacklogQueryVariables>;
export const UserDailyPlanDocument = gql`
    query UserDailyPlan($date: String!) {
  userDailyPlanForDate(date: $date) {
    id
    maxTimeBlockCount
  }
}
    `;

/**
 * __useUserDailyPlanQuery__
 *
 * To run a query within a React component, call `useUserDailyPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDailyPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDailyPlanQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUserDailyPlanQuery(baseOptions: Apollo.QueryHookOptions<UserDailyPlanQuery, UserDailyPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserDailyPlanQuery, UserDailyPlanQueryVariables>(UserDailyPlanDocument, options);
      }
export function useUserDailyPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserDailyPlanQuery, UserDailyPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserDailyPlanQuery, UserDailyPlanQueryVariables>(UserDailyPlanDocument, options);
        }
export type UserDailyPlanQueryHookResult = ReturnType<typeof useUserDailyPlanQuery>;
export type UserDailyPlanLazyQueryHookResult = ReturnType<typeof useUserDailyPlanLazyQuery>;
export type UserDailyPlanQueryResult = Apollo.QueryResult<UserDailyPlanQuery, UserDailyPlanQueryVariables>;
export const CreateUserDailyPlanForDateDocument = gql`
    mutation CreateUserDailyPlanForDate($dailyPlanId: String!, $date: String!) {
  createUserDailyPlanForDate(dailyPlanId: $dailyPlanId, date: $date) {
    success
    error
  }
}
    `;
export type CreateUserDailyPlanForDateMutationFn = Apollo.MutationFunction<CreateUserDailyPlanForDateMutation, CreateUserDailyPlanForDateMutationVariables>;

/**
 * __useCreateUserDailyPlanForDateMutation__
 *
 * To run a mutation, you first call `useCreateUserDailyPlanForDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserDailyPlanForDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserDailyPlanForDateMutation, { data, loading, error }] = useCreateUserDailyPlanForDateMutation({
 *   variables: {
 *      dailyPlanId: // value for 'dailyPlanId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useCreateUserDailyPlanForDateMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserDailyPlanForDateMutation, CreateUserDailyPlanForDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserDailyPlanForDateMutation, CreateUserDailyPlanForDateMutationVariables>(CreateUserDailyPlanForDateDocument, options);
      }
export type CreateUserDailyPlanForDateMutationHookResult = ReturnType<typeof useCreateUserDailyPlanForDateMutation>;
export type CreateUserDailyPlanForDateMutationResult = Apollo.MutationResult<CreateUserDailyPlanForDateMutation>;
export type CreateUserDailyPlanForDateMutationOptions = Apollo.BaseMutationOptions<CreateUserDailyPlanForDateMutation, CreateUserDailyPlanForDateMutationVariables>;
export const AddTaskToPlanDocument = gql`
    mutation AddTaskToPlan($dailyPlanId: String!, $date: String!, $taskGroupId: String!, $taskGroupOrder: Float!, $taskId: String!, $isGrayTime: Boolean!) {
  addTaskToPlan(
    dailyPlanId: $dailyPlanId
    date: $date
    taskGroupId: $taskGroupId
    taskGroupOrder: $taskGroupOrder
    taskId: $taskId
    isGrayTime: $isGrayTime
  ) {
    success
    error
  }
}
    `;
export type AddTaskToPlanMutationFn = Apollo.MutationFunction<AddTaskToPlanMutation, AddTaskToPlanMutationVariables>;

/**
 * __useAddTaskToPlanMutation__
 *
 * To run a mutation, you first call `useAddTaskToPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaskToPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaskToPlanMutation, { data, loading, error }] = useAddTaskToPlanMutation({
 *   variables: {
 *      dailyPlanId: // value for 'dailyPlanId'
 *      date: // value for 'date'
 *      taskGroupId: // value for 'taskGroupId'
 *      taskGroupOrder: // value for 'taskGroupOrder'
 *      taskId: // value for 'taskId'
 *      isGrayTime: // value for 'isGrayTime'
 *   },
 * });
 */
export function useAddTaskToPlanMutation(baseOptions?: Apollo.MutationHookOptions<AddTaskToPlanMutation, AddTaskToPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTaskToPlanMutation, AddTaskToPlanMutationVariables>(AddTaskToPlanDocument, options);
      }
export type AddTaskToPlanMutationHookResult = ReturnType<typeof useAddTaskToPlanMutation>;
export type AddTaskToPlanMutationResult = Apollo.MutationResult<AddTaskToPlanMutation>;
export type AddTaskToPlanMutationOptions = Apollo.BaseMutationOptions<AddTaskToPlanMutation, AddTaskToPlanMutationVariables>;
export const AddNewTaskToPlanDocument = gql`
    mutation AddNewTaskToPlan($dailyPlanId: String!, $date: String!, $taskGroupId: String!, $taskGroupOrder: Float!, $taskId: String!, $roleId: String!, $name: String!, $order: Float!, $isGrayTime: Boolean!) {
  addNewTaskToPlan(
    dailyPlanId: $dailyPlanId
    date: $date
    taskGroupId: $taskGroupId
    taskGroupOrder: $taskGroupOrder
    taskId: $taskId
    roleId: $roleId
    name: $name
    order: $order
    isGrayTime: $isGrayTime
  ) {
    success
    error
  }
}
    `;
export type AddNewTaskToPlanMutationFn = Apollo.MutationFunction<AddNewTaskToPlanMutation, AddNewTaskToPlanMutationVariables>;

/**
 * __useAddNewTaskToPlanMutation__
 *
 * To run a mutation, you first call `useAddNewTaskToPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewTaskToPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewTaskToPlanMutation, { data, loading, error }] = useAddNewTaskToPlanMutation({
 *   variables: {
 *      dailyPlanId: // value for 'dailyPlanId'
 *      date: // value for 'date'
 *      taskGroupId: // value for 'taskGroupId'
 *      taskGroupOrder: // value for 'taskGroupOrder'
 *      taskId: // value for 'taskId'
 *      roleId: // value for 'roleId'
 *      name: // value for 'name'
 *      order: // value for 'order'
 *      isGrayTime: // value for 'isGrayTime'
 *   },
 * });
 */
export function useAddNewTaskToPlanMutation(baseOptions?: Apollo.MutationHookOptions<AddNewTaskToPlanMutation, AddNewTaskToPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNewTaskToPlanMutation, AddNewTaskToPlanMutationVariables>(AddNewTaskToPlanDocument, options);
      }
export type AddNewTaskToPlanMutationHookResult = ReturnType<typeof useAddNewTaskToPlanMutation>;
export type AddNewTaskToPlanMutationResult = Apollo.MutationResult<AddNewTaskToPlanMutation>;
export type AddNewTaskToPlanMutationOptions = Apollo.BaseMutationOptions<AddNewTaskToPlanMutation, AddNewTaskToPlanMutationVariables>;
export const SetPlanStartTimeBlockNumberDocument = gql`
    mutation SetPlanStartTimeBlockNumber($dailyPlanId: String!, $date: String!, $startTimeBlockNumber: Int!) {
  setPlanStartTimeBlockNumber(
    dailyPlanId: $dailyPlanId
    date: $date
    startTimeBlockNumber: $startTimeBlockNumber
  ) {
    success
    error
  }
}
    `;
export type SetPlanStartTimeBlockNumberMutationFn = Apollo.MutationFunction<SetPlanStartTimeBlockNumberMutation, SetPlanStartTimeBlockNumberMutationVariables>;

/**
 * __useSetPlanStartTimeBlockNumberMutation__
 *
 * To run a mutation, you first call `useSetPlanStartTimeBlockNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPlanStartTimeBlockNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPlanStartTimeBlockNumberMutation, { data, loading, error }] = useSetPlanStartTimeBlockNumberMutation({
 *   variables: {
 *      dailyPlanId: // value for 'dailyPlanId'
 *      date: // value for 'date'
 *      startTimeBlockNumber: // value for 'startTimeBlockNumber'
 *   },
 * });
 */
export function useSetPlanStartTimeBlockNumberMutation(baseOptions?: Apollo.MutationHookOptions<SetPlanStartTimeBlockNumberMutation, SetPlanStartTimeBlockNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPlanStartTimeBlockNumberMutation, SetPlanStartTimeBlockNumberMutationVariables>(SetPlanStartTimeBlockNumberDocument, options);
      }
export type SetPlanStartTimeBlockNumberMutationHookResult = ReturnType<typeof useSetPlanStartTimeBlockNumberMutation>;
export type SetPlanStartTimeBlockNumberMutationResult = Apollo.MutationResult<SetPlanStartTimeBlockNumberMutation>;
export type SetPlanStartTimeBlockNumberMutationOptions = Apollo.BaseMutationOptions<SetPlanStartTimeBlockNumberMutation, SetPlanStartTimeBlockNumberMutationVariables>;
export const SetPlanEndTimeBlockNumberDocument = gql`
    mutation SetPlanEndTimeBlockNumber($dailyPlanId: String!, $date: String!, $endTimeBlockNumber: Int!) {
  setPlanEndTimeBlockNumber(
    dailyPlanId: $dailyPlanId
    date: $date
    endTimeBlockNumber: $endTimeBlockNumber
  ) {
    success
    error
  }
}
    `;
export type SetPlanEndTimeBlockNumberMutationFn = Apollo.MutationFunction<SetPlanEndTimeBlockNumberMutation, SetPlanEndTimeBlockNumberMutationVariables>;

/**
 * __useSetPlanEndTimeBlockNumberMutation__
 *
 * To run a mutation, you first call `useSetPlanEndTimeBlockNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPlanEndTimeBlockNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPlanEndTimeBlockNumberMutation, { data, loading, error }] = useSetPlanEndTimeBlockNumberMutation({
 *   variables: {
 *      dailyPlanId: // value for 'dailyPlanId'
 *      date: // value for 'date'
 *      endTimeBlockNumber: // value for 'endTimeBlockNumber'
 *   },
 * });
 */
export function useSetPlanEndTimeBlockNumberMutation(baseOptions?: Apollo.MutationHookOptions<SetPlanEndTimeBlockNumberMutation, SetPlanEndTimeBlockNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPlanEndTimeBlockNumberMutation, SetPlanEndTimeBlockNumberMutationVariables>(SetPlanEndTimeBlockNumberDocument, options);
      }
export type SetPlanEndTimeBlockNumberMutationHookResult = ReturnType<typeof useSetPlanEndTimeBlockNumberMutation>;
export type SetPlanEndTimeBlockNumberMutationResult = Apollo.MutationResult<SetPlanEndTimeBlockNumberMutation>;
export type SetPlanEndTimeBlockNumberMutationOptions = Apollo.BaseMutationOptions<SetPlanEndTimeBlockNumberMutation, SetPlanEndTimeBlockNumberMutationVariables>;
export const SetUserPlanBlockDocument = gql`
    mutation SetUserPlanBlock($dailyPlanId: String!, $date: String!, $blockNumber: Int!, $roleId: String, $plannedBlockType: String) {
  setUserPlanBlock(
    dailyPlanId: $dailyPlanId
    date: $date
    blockNumber: $blockNumber
    roleId: $roleId
    plannedBlockType: $plannedBlockType
  ) {
    success
    error
  }
}
    `;
export type SetUserPlanBlockMutationFn = Apollo.MutationFunction<SetUserPlanBlockMutation, SetUserPlanBlockMutationVariables>;

/**
 * __useSetUserPlanBlockMutation__
 *
 * To run a mutation, you first call `useSetUserPlanBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserPlanBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserPlanBlockMutation, { data, loading, error }] = useSetUserPlanBlockMutation({
 *   variables: {
 *      dailyPlanId: // value for 'dailyPlanId'
 *      date: // value for 'date'
 *      blockNumber: // value for 'blockNumber'
 *      roleId: // value for 'roleId'
 *      plannedBlockType: // value for 'plannedBlockType'
 *   },
 * });
 */
export function useSetUserPlanBlockMutation(baseOptions?: Apollo.MutationHookOptions<SetUserPlanBlockMutation, SetUserPlanBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserPlanBlockMutation, SetUserPlanBlockMutationVariables>(SetUserPlanBlockDocument, options);
      }
export type SetUserPlanBlockMutationHookResult = ReturnType<typeof useSetUserPlanBlockMutation>;
export type SetUserPlanBlockMutationResult = Apollo.MutationResult<SetUserPlanBlockMutation>;
export type SetUserPlanBlockMutationOptions = Apollo.BaseMutationOptions<SetUserPlanBlockMutation, SetUserPlanBlockMutationVariables>;
export const SetUserPlanCollapsedForTaskGroupsDocument = gql`
    mutation SetUserPlanCollapsedForTaskGroups($dailyPlanId: String!, $collapsed: Boolean!) {
  setUserPlanCollapsedForTaskGroups(
    dailyPlanId: $dailyPlanId
    collapsed: $collapsed
  ) {
    success
    error
  }
}
    `;
export type SetUserPlanCollapsedForTaskGroupsMutationFn = Apollo.MutationFunction<SetUserPlanCollapsedForTaskGroupsMutation, SetUserPlanCollapsedForTaskGroupsMutationVariables>;

/**
 * __useSetUserPlanCollapsedForTaskGroupsMutation__
 *
 * To run a mutation, you first call `useSetUserPlanCollapsedForTaskGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserPlanCollapsedForTaskGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserPlanCollapsedForTaskGroupsMutation, { data, loading, error }] = useSetUserPlanCollapsedForTaskGroupsMutation({
 *   variables: {
 *      dailyPlanId: // value for 'dailyPlanId'
 *      collapsed: // value for 'collapsed'
 *   },
 * });
 */
export function useSetUserPlanCollapsedForTaskGroupsMutation(baseOptions?: Apollo.MutationHookOptions<SetUserPlanCollapsedForTaskGroupsMutation, SetUserPlanCollapsedForTaskGroupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserPlanCollapsedForTaskGroupsMutation, SetUserPlanCollapsedForTaskGroupsMutationVariables>(SetUserPlanCollapsedForTaskGroupsDocument, options);
      }
export type SetUserPlanCollapsedForTaskGroupsMutationHookResult = ReturnType<typeof useSetUserPlanCollapsedForTaskGroupsMutation>;
export type SetUserPlanCollapsedForTaskGroupsMutationResult = Apollo.MutationResult<SetUserPlanCollapsedForTaskGroupsMutation>;
export type SetUserPlanCollapsedForTaskGroupsMutationOptions = Apollo.BaseMutationOptions<SetUserPlanCollapsedForTaskGroupsMutation, SetUserPlanCollapsedForTaskGroupsMutationVariables>;
export const SetUserTaskGroupRoleDocument = gql`
    mutation SetUserTaskGroupRole($dailyPlanTaskGroupId: String!, $roleId: String!) {
  setUserTaskGroupRole(
    dailyPlanTaskGroupId: $dailyPlanTaskGroupId
    roleId: $roleId
  ) {
    success
    error
  }
}
    `;
export type SetUserTaskGroupRoleMutationFn = Apollo.MutationFunction<SetUserTaskGroupRoleMutation, SetUserTaskGroupRoleMutationVariables>;

/**
 * __useSetUserTaskGroupRoleMutation__
 *
 * To run a mutation, you first call `useSetUserTaskGroupRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserTaskGroupRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserTaskGroupRoleMutation, { data, loading, error }] = useSetUserTaskGroupRoleMutation({
 *   variables: {
 *      dailyPlanTaskGroupId: // value for 'dailyPlanTaskGroupId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useSetUserTaskGroupRoleMutation(baseOptions?: Apollo.MutationHookOptions<SetUserTaskGroupRoleMutation, SetUserTaskGroupRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserTaskGroupRoleMutation, SetUserTaskGroupRoleMutationVariables>(SetUserTaskGroupRoleDocument, options);
      }
export type SetUserTaskGroupRoleMutationHookResult = ReturnType<typeof useSetUserTaskGroupRoleMutation>;
export type SetUserTaskGroupRoleMutationResult = Apollo.MutationResult<SetUserTaskGroupRoleMutation>;
export type SetUserTaskGroupRoleMutationOptions = Apollo.BaseMutationOptions<SetUserTaskGroupRoleMutation, SetUserTaskGroupRoleMutationVariables>;
export const SetUserTaskGroupTimeBlockCountDocument = gql`
    mutation SetUserTaskGroupTimeBlockCount($dailyPlanTaskGroupId: String!, $timeBlockCount: Int!) {
  setUserTaskGroupTimeBlockCount(
    dailyPlanTaskGroupId: $dailyPlanTaskGroupId
    timeBlockCount: $timeBlockCount
  ) {
    success
    error
  }
}
    `;
export type SetUserTaskGroupTimeBlockCountMutationFn = Apollo.MutationFunction<SetUserTaskGroupTimeBlockCountMutation, SetUserTaskGroupTimeBlockCountMutationVariables>;

/**
 * __useSetUserTaskGroupTimeBlockCountMutation__
 *
 * To run a mutation, you first call `useSetUserTaskGroupTimeBlockCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserTaskGroupTimeBlockCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserTaskGroupTimeBlockCountMutation, { data, loading, error }] = useSetUserTaskGroupTimeBlockCountMutation({
 *   variables: {
 *      dailyPlanTaskGroupId: // value for 'dailyPlanTaskGroupId'
 *      timeBlockCount: // value for 'timeBlockCount'
 *   },
 * });
 */
export function useSetUserTaskGroupTimeBlockCountMutation(baseOptions?: Apollo.MutationHookOptions<SetUserTaskGroupTimeBlockCountMutation, SetUserTaskGroupTimeBlockCountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserTaskGroupTimeBlockCountMutation, SetUserTaskGroupTimeBlockCountMutationVariables>(SetUserTaskGroupTimeBlockCountDocument, options);
      }
export type SetUserTaskGroupTimeBlockCountMutationHookResult = ReturnType<typeof useSetUserTaskGroupTimeBlockCountMutation>;
export type SetUserTaskGroupTimeBlockCountMutationResult = Apollo.MutationResult<SetUserTaskGroupTimeBlockCountMutation>;
export type SetUserTaskGroupTimeBlockCountMutationOptions = Apollo.BaseMutationOptions<SetUserTaskGroupTimeBlockCountMutation, SetUserTaskGroupTimeBlockCountMutationVariables>;
export const SetUserTaskGroupCompletedTimeBlockCountDocument = gql`
    mutation SetUserTaskGroupCompletedTimeBlockCount($dailyPlanTaskGroupId: String!, $completedTimeBlockCount: Int!) {
  setUserTaskGroupCompletedTimeBlockCount(
    dailyPlanTaskGroupId: $dailyPlanTaskGroupId
    completedTimeBlockCount: $completedTimeBlockCount
  ) {
    success
    error
  }
}
    `;
export type SetUserTaskGroupCompletedTimeBlockCountMutationFn = Apollo.MutationFunction<SetUserTaskGroupCompletedTimeBlockCountMutation, SetUserTaskGroupCompletedTimeBlockCountMutationVariables>;

/**
 * __useSetUserTaskGroupCompletedTimeBlockCountMutation__
 *
 * To run a mutation, you first call `useSetUserTaskGroupCompletedTimeBlockCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserTaskGroupCompletedTimeBlockCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserTaskGroupCompletedTimeBlockCountMutation, { data, loading, error }] = useSetUserTaskGroupCompletedTimeBlockCountMutation({
 *   variables: {
 *      dailyPlanTaskGroupId: // value for 'dailyPlanTaskGroupId'
 *      completedTimeBlockCount: // value for 'completedTimeBlockCount'
 *   },
 * });
 */
export function useSetUserTaskGroupCompletedTimeBlockCountMutation(baseOptions?: Apollo.MutationHookOptions<SetUserTaskGroupCompletedTimeBlockCountMutation, SetUserTaskGroupCompletedTimeBlockCountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserTaskGroupCompletedTimeBlockCountMutation, SetUserTaskGroupCompletedTimeBlockCountMutationVariables>(SetUserTaskGroupCompletedTimeBlockCountDocument, options);
      }
export type SetUserTaskGroupCompletedTimeBlockCountMutationHookResult = ReturnType<typeof useSetUserTaskGroupCompletedTimeBlockCountMutation>;
export type SetUserTaskGroupCompletedTimeBlockCountMutationResult = Apollo.MutationResult<SetUserTaskGroupCompletedTimeBlockCountMutation>;
export type SetUserTaskGroupCompletedTimeBlockCountMutationOptions = Apollo.BaseMutationOptions<SetUserTaskGroupCompletedTimeBlockCountMutation, SetUserTaskGroupCompletedTimeBlockCountMutationVariables>;
export const SetUserTaskGroupOrderDocument = gql`
    mutation SetUserTaskGroupOrder($dailyPlanTaskGroupId: String!, $order: Float!) {
  setUserTaskGroupOrder(
    dailyPlanTaskGroupId: $dailyPlanTaskGroupId
    order: $order
  ) {
    success
    error
  }
}
    `;
export type SetUserTaskGroupOrderMutationFn = Apollo.MutationFunction<SetUserTaskGroupOrderMutation, SetUserTaskGroupOrderMutationVariables>;

/**
 * __useSetUserTaskGroupOrderMutation__
 *
 * To run a mutation, you first call `useSetUserTaskGroupOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserTaskGroupOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserTaskGroupOrderMutation, { data, loading, error }] = useSetUserTaskGroupOrderMutation({
 *   variables: {
 *      dailyPlanTaskGroupId: // value for 'dailyPlanTaskGroupId'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSetUserTaskGroupOrderMutation(baseOptions?: Apollo.MutationHookOptions<SetUserTaskGroupOrderMutation, SetUserTaskGroupOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserTaskGroupOrderMutation, SetUserTaskGroupOrderMutationVariables>(SetUserTaskGroupOrderDocument, options);
      }
export type SetUserTaskGroupOrderMutationHookResult = ReturnType<typeof useSetUserTaskGroupOrderMutation>;
export type SetUserTaskGroupOrderMutationResult = Apollo.MutationResult<SetUserTaskGroupOrderMutation>;
export type SetUserTaskGroupOrderMutationOptions = Apollo.BaseMutationOptions<SetUserTaskGroupOrderMutation, SetUserTaskGroupOrderMutationVariables>;
export const SetUserTaskGroupCollapsedDocument = gql`
    mutation SetUserTaskGroupCollapsed($dailyPlanTaskGroupId: String!, $collapsed: Boolean!) {
  setUserTaskGroupCollapsed(
    dailyPlanTaskGroupId: $dailyPlanTaskGroupId
    collapsed: $collapsed
  ) {
    success
    error
  }
}
    `;
export type SetUserTaskGroupCollapsedMutationFn = Apollo.MutationFunction<SetUserTaskGroupCollapsedMutation, SetUserTaskGroupCollapsedMutationVariables>;

/**
 * __useSetUserTaskGroupCollapsedMutation__
 *
 * To run a mutation, you first call `useSetUserTaskGroupCollapsedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserTaskGroupCollapsedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserTaskGroupCollapsedMutation, { data, loading, error }] = useSetUserTaskGroupCollapsedMutation({
 *   variables: {
 *      dailyPlanTaskGroupId: // value for 'dailyPlanTaskGroupId'
 *      collapsed: // value for 'collapsed'
 *   },
 * });
 */
export function useSetUserTaskGroupCollapsedMutation(baseOptions?: Apollo.MutationHookOptions<SetUserTaskGroupCollapsedMutation, SetUserTaskGroupCollapsedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserTaskGroupCollapsedMutation, SetUserTaskGroupCollapsedMutationVariables>(SetUserTaskGroupCollapsedDocument, options);
      }
export type SetUserTaskGroupCollapsedMutationHookResult = ReturnType<typeof useSetUserTaskGroupCollapsedMutation>;
export type SetUserTaskGroupCollapsedMutationResult = Apollo.MutationResult<SetUserTaskGroupCollapsedMutation>;
export type SetUserTaskGroupCollapsedMutationOptions = Apollo.BaseMutationOptions<SetUserTaskGroupCollapsedMutation, SetUserTaskGroupCollapsedMutationVariables>;
export const SetUserTaskGroupGrayTimeDocument = gql`
    mutation SetUserTaskGroupGrayTime($dailyPlanTaskGroupId: String!, $isGrayTime: Boolean!) {
  setUserTaskGroupGrayTime(
    dailyPlanTaskGroupId: $dailyPlanTaskGroupId
    isGrayTime: $isGrayTime
  ) {
    success
    error
  }
}
    `;
export type SetUserTaskGroupGrayTimeMutationFn = Apollo.MutationFunction<SetUserTaskGroupGrayTimeMutation, SetUserTaskGroupGrayTimeMutationVariables>;

/**
 * __useSetUserTaskGroupGrayTimeMutation__
 *
 * To run a mutation, you first call `useSetUserTaskGroupGrayTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserTaskGroupGrayTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserTaskGroupGrayTimeMutation, { data, loading, error }] = useSetUserTaskGroupGrayTimeMutation({
 *   variables: {
 *      dailyPlanTaskGroupId: // value for 'dailyPlanTaskGroupId'
 *      isGrayTime: // value for 'isGrayTime'
 *   },
 * });
 */
export function useSetUserTaskGroupGrayTimeMutation(baseOptions?: Apollo.MutationHookOptions<SetUserTaskGroupGrayTimeMutation, SetUserTaskGroupGrayTimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserTaskGroupGrayTimeMutation, SetUserTaskGroupGrayTimeMutationVariables>(SetUserTaskGroupGrayTimeDocument, options);
      }
export type SetUserTaskGroupGrayTimeMutationHookResult = ReturnType<typeof useSetUserTaskGroupGrayTimeMutation>;
export type SetUserTaskGroupGrayTimeMutationResult = Apollo.MutationResult<SetUserTaskGroupGrayTimeMutation>;
export type SetUserTaskGroupGrayTimeMutationOptions = Apollo.BaseMutationOptions<SetUserTaskGroupGrayTimeMutation, SetUserTaskGroupGrayTimeMutationVariables>;
export const SetUserTaskGroupNameDocument = gql`
    mutation SetUserTaskGroupName($dailyPlanTaskGroupId: String!, $name: String) {
  setUserTaskGroupName(dailyPlanTaskGroupId: $dailyPlanTaskGroupId, name: $name) {
    success
    error
  }
}
    `;
export type SetUserTaskGroupNameMutationFn = Apollo.MutationFunction<SetUserTaskGroupNameMutation, SetUserTaskGroupNameMutationVariables>;

/**
 * __useSetUserTaskGroupNameMutation__
 *
 * To run a mutation, you first call `useSetUserTaskGroupNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserTaskGroupNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserTaskGroupNameMutation, { data, loading, error }] = useSetUserTaskGroupNameMutation({
 *   variables: {
 *      dailyPlanTaskGroupId: // value for 'dailyPlanTaskGroupId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSetUserTaskGroupNameMutation(baseOptions?: Apollo.MutationHookOptions<SetUserTaskGroupNameMutation, SetUserTaskGroupNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserTaskGroupNameMutation, SetUserTaskGroupNameMutationVariables>(SetUserTaskGroupNameDocument, options);
      }
export type SetUserTaskGroupNameMutationHookResult = ReturnType<typeof useSetUserTaskGroupNameMutation>;
export type SetUserTaskGroupNameMutationResult = Apollo.MutationResult<SetUserTaskGroupNameMutation>;
export type SetUserTaskGroupNameMutationOptions = Apollo.BaseMutationOptions<SetUserTaskGroupNameMutation, SetUserTaskGroupNameMutationVariables>;
export const SetUserTaskGroupStartTimeBlockNumberDocument = gql`
    mutation SetUserTaskGroupStartTimeBlockNumber($dailyPlanTaskGroupId: String!, $startTimeBlockNumber: Int) {
  setUserTaskGroupStartTimeBlockNumber(
    dailyPlanTaskGroupId: $dailyPlanTaskGroupId
    startTimeBlockNumber: $startTimeBlockNumber
  ) {
    success
    error
  }
}
    `;
export type SetUserTaskGroupStartTimeBlockNumberMutationFn = Apollo.MutationFunction<SetUserTaskGroupStartTimeBlockNumberMutation, SetUserTaskGroupStartTimeBlockNumberMutationVariables>;

/**
 * __useSetUserTaskGroupStartTimeBlockNumberMutation__
 *
 * To run a mutation, you first call `useSetUserTaskGroupStartTimeBlockNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserTaskGroupStartTimeBlockNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserTaskGroupStartTimeBlockNumberMutation, { data, loading, error }] = useSetUserTaskGroupStartTimeBlockNumberMutation({
 *   variables: {
 *      dailyPlanTaskGroupId: // value for 'dailyPlanTaskGroupId'
 *      startTimeBlockNumber: // value for 'startTimeBlockNumber'
 *   },
 * });
 */
export function useSetUserTaskGroupStartTimeBlockNumberMutation(baseOptions?: Apollo.MutationHookOptions<SetUserTaskGroupStartTimeBlockNumberMutation, SetUserTaskGroupStartTimeBlockNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserTaskGroupStartTimeBlockNumberMutation, SetUserTaskGroupStartTimeBlockNumberMutationVariables>(SetUserTaskGroupStartTimeBlockNumberDocument, options);
      }
export type SetUserTaskGroupStartTimeBlockNumberMutationHookResult = ReturnType<typeof useSetUserTaskGroupStartTimeBlockNumberMutation>;
export type SetUserTaskGroupStartTimeBlockNumberMutationResult = Apollo.MutationResult<SetUserTaskGroupStartTimeBlockNumberMutation>;
export type SetUserTaskGroupStartTimeBlockNumberMutationOptions = Apollo.BaseMutationOptions<SetUserTaskGroupStartTimeBlockNumberMutation, SetUserTaskGroupStartTimeBlockNumberMutationVariables>;
export const AddNewTaskToGroupDocument = gql`
    mutation AddNewTaskToGroup($taskId: String!, $dailyPlanTaskGroupId: String!, $name: String!, $order: Float!) {
  addNewTaskToGroup(
    taskId: $taskId
    dailyPlanTaskGroupId: $dailyPlanTaskGroupId
    name: $name
    order: $order
  ) {
    success
    error
  }
}
    `;
export type AddNewTaskToGroupMutationFn = Apollo.MutationFunction<AddNewTaskToGroupMutation, AddNewTaskToGroupMutationVariables>;

/**
 * __useAddNewTaskToGroupMutation__
 *
 * To run a mutation, you first call `useAddNewTaskToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewTaskToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewTaskToGroupMutation, { data, loading, error }] = useAddNewTaskToGroupMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      dailyPlanTaskGroupId: // value for 'dailyPlanTaskGroupId'
 *      name: // value for 'name'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAddNewTaskToGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddNewTaskToGroupMutation, AddNewTaskToGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNewTaskToGroupMutation, AddNewTaskToGroupMutationVariables>(AddNewTaskToGroupDocument, options);
      }
export type AddNewTaskToGroupMutationHookResult = ReturnType<typeof useAddNewTaskToGroupMutation>;
export type AddNewTaskToGroupMutationResult = Apollo.MutationResult<AddNewTaskToGroupMutation>;
export type AddNewTaskToGroupMutationOptions = Apollo.BaseMutationOptions<AddNewTaskToGroupMutation, AddNewTaskToGroupMutationVariables>;
export const AddTimeCardToPlanDocument = gql`
    mutation AddTimeCardToPlan($dailyPlanId: String!, $date: String!, $dailyPlanTimeCardId: String!, $name: String, $order: Float!, $roleId: String!, $isGrayTime: Boolean!) {
  addTimeCardToPlan(
    dailyPlanId: $dailyPlanId
    date: $date
    dailyPlanTimeCardId: $dailyPlanTimeCardId
    name: $name
    order: $order
    roleId: $roleId
    isGrayTime: $isGrayTime
  ) {
    success
    error
  }
}
    `;
export type AddTimeCardToPlanMutationFn = Apollo.MutationFunction<AddTimeCardToPlanMutation, AddTimeCardToPlanMutationVariables>;

/**
 * __useAddTimeCardToPlanMutation__
 *
 * To run a mutation, you first call `useAddTimeCardToPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTimeCardToPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTimeCardToPlanMutation, { data, loading, error }] = useAddTimeCardToPlanMutation({
 *   variables: {
 *      dailyPlanId: // value for 'dailyPlanId'
 *      date: // value for 'date'
 *      dailyPlanTimeCardId: // value for 'dailyPlanTimeCardId'
 *      name: // value for 'name'
 *      order: // value for 'order'
 *      roleId: // value for 'roleId'
 *      isGrayTime: // value for 'isGrayTime'
 *   },
 * });
 */
export function useAddTimeCardToPlanMutation(baseOptions?: Apollo.MutationHookOptions<AddTimeCardToPlanMutation, AddTimeCardToPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTimeCardToPlanMutation, AddTimeCardToPlanMutationVariables>(AddTimeCardToPlanDocument, options);
      }
export type AddTimeCardToPlanMutationHookResult = ReturnType<typeof useAddTimeCardToPlanMutation>;
export type AddTimeCardToPlanMutationResult = Apollo.MutationResult<AddTimeCardToPlanMutation>;
export type AddTimeCardToPlanMutationOptions = Apollo.BaseMutationOptions<AddTimeCardToPlanMutation, AddTimeCardToPlanMutationVariables>;
export const DeleteTimeCardFromPlanDocument = gql`
    mutation DeleteTimeCardFromPlan($dailyPlanTimeCardId: String!) {
  deleteTimeCardFromPlan(dailyPlanTimeCardId: $dailyPlanTimeCardId) {
    success
    error
  }
}
    `;
export type DeleteTimeCardFromPlanMutationFn = Apollo.MutationFunction<DeleteTimeCardFromPlanMutation, DeleteTimeCardFromPlanMutationVariables>;

/**
 * __useDeleteTimeCardFromPlanMutation__
 *
 * To run a mutation, you first call `useDeleteTimeCardFromPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeCardFromPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeCardFromPlanMutation, { data, loading, error }] = useDeleteTimeCardFromPlanMutation({
 *   variables: {
 *      dailyPlanTimeCardId: // value for 'dailyPlanTimeCardId'
 *   },
 * });
 */
export function useDeleteTimeCardFromPlanMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimeCardFromPlanMutation, DeleteTimeCardFromPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTimeCardFromPlanMutation, DeleteTimeCardFromPlanMutationVariables>(DeleteTimeCardFromPlanDocument, options);
      }
export type DeleteTimeCardFromPlanMutationHookResult = ReturnType<typeof useDeleteTimeCardFromPlanMutation>;
export type DeleteTimeCardFromPlanMutationResult = Apollo.MutationResult<DeleteTimeCardFromPlanMutation>;
export type DeleteTimeCardFromPlanMutationOptions = Apollo.BaseMutationOptions<DeleteTimeCardFromPlanMutation, DeleteTimeCardFromPlanMutationVariables>;
export const MoveUserTaskToGroupDocument = gql`
    mutation MoveUserTaskToGroup($taskId: String!, $planId: String!, $dailyPlanTaskGroupId: String!, $groupOrder: Float!, $taskOrder: Float!) {
  moveUserTaskToGroup(
    taskId: $taskId
    planId: $planId
    dailyPlanTaskGroupId: $dailyPlanTaskGroupId
    groupOrder: $groupOrder
    taskOrder: $taskOrder
  ) {
    success
    error
  }
}
    `;
export type MoveUserTaskToGroupMutationFn = Apollo.MutationFunction<MoveUserTaskToGroupMutation, MoveUserTaskToGroupMutationVariables>;

/**
 * __useMoveUserTaskToGroupMutation__
 *
 * To run a mutation, you first call `useMoveUserTaskToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveUserTaskToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveUserTaskToGroupMutation, { data, loading, error }] = useMoveUserTaskToGroupMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      planId: // value for 'planId'
 *      dailyPlanTaskGroupId: // value for 'dailyPlanTaskGroupId'
 *      groupOrder: // value for 'groupOrder'
 *      taskOrder: // value for 'taskOrder'
 *   },
 * });
 */
export function useMoveUserTaskToGroupMutation(baseOptions?: Apollo.MutationHookOptions<MoveUserTaskToGroupMutation, MoveUserTaskToGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveUserTaskToGroupMutation, MoveUserTaskToGroupMutationVariables>(MoveUserTaskToGroupDocument, options);
      }
export type MoveUserTaskToGroupMutationHookResult = ReturnType<typeof useMoveUserTaskToGroupMutation>;
export type MoveUserTaskToGroupMutationResult = Apollo.MutationResult<MoveUserTaskToGroupMutation>;
export type MoveUserTaskToGroupMutationOptions = Apollo.BaseMutationOptions<MoveUserTaskToGroupMutation, MoveUserTaskToGroupMutationVariables>;
export const MakeRoutineFromTaskGroupDocument = gql`
    mutation MakeRoutineFromTaskGroup($routineId: String!, $name: String!, $dailyPlanTaskGroupId: String!) {
  makeRoutineFromTaskGroup(
    routineId: $routineId
    name: $name
    dailyPlanTaskGroupId: $dailyPlanTaskGroupId
  ) {
    success
    error
    idsInserted
  }
}
    `;
export type MakeRoutineFromTaskGroupMutationFn = Apollo.MutationFunction<MakeRoutineFromTaskGroupMutation, MakeRoutineFromTaskGroupMutationVariables>;

/**
 * __useMakeRoutineFromTaskGroupMutation__
 *
 * To run a mutation, you first call `useMakeRoutineFromTaskGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeRoutineFromTaskGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeRoutineFromTaskGroupMutation, { data, loading, error }] = useMakeRoutineFromTaskGroupMutation({
 *   variables: {
 *      routineId: // value for 'routineId'
 *      name: // value for 'name'
 *      dailyPlanTaskGroupId: // value for 'dailyPlanTaskGroupId'
 *   },
 * });
 */
export function useMakeRoutineFromTaskGroupMutation(baseOptions?: Apollo.MutationHookOptions<MakeRoutineFromTaskGroupMutation, MakeRoutineFromTaskGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeRoutineFromTaskGroupMutation, MakeRoutineFromTaskGroupMutationVariables>(MakeRoutineFromTaskGroupDocument, options);
      }
export type MakeRoutineFromTaskGroupMutationHookResult = ReturnType<typeof useMakeRoutineFromTaskGroupMutation>;
export type MakeRoutineFromTaskGroupMutationResult = Apollo.MutationResult<MakeRoutineFromTaskGroupMutation>;
export type MakeRoutineFromTaskGroupMutationOptions = Apollo.BaseMutationOptions<MakeRoutineFromTaskGroupMutation, MakeRoutineFromTaskGroupMutationVariables>;
export const UpdateRoutineFromTaskGroupDocument = gql`
    mutation UpdateRoutineFromTaskGroup($routineId: String!, $dailyPlanTaskGroupId: String!) {
  updateRoutineFromTaskGroup(
    routineId: $routineId
    dailyPlanTaskGroupId: $dailyPlanTaskGroupId
  ) {
    success
    error
    idsInserted
  }
}
    `;
export type UpdateRoutineFromTaskGroupMutationFn = Apollo.MutationFunction<UpdateRoutineFromTaskGroupMutation, UpdateRoutineFromTaskGroupMutationVariables>;

/**
 * __useUpdateRoutineFromTaskGroupMutation__
 *
 * To run a mutation, you first call `useUpdateRoutineFromTaskGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoutineFromTaskGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoutineFromTaskGroupMutation, { data, loading, error }] = useUpdateRoutineFromTaskGroupMutation({
 *   variables: {
 *      routineId: // value for 'routineId'
 *      dailyPlanTaskGroupId: // value for 'dailyPlanTaskGroupId'
 *   },
 * });
 */
export function useUpdateRoutineFromTaskGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoutineFromTaskGroupMutation, UpdateRoutineFromTaskGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoutineFromTaskGroupMutation, UpdateRoutineFromTaskGroupMutationVariables>(UpdateRoutineFromTaskGroupDocument, options);
      }
export type UpdateRoutineFromTaskGroupMutationHookResult = ReturnType<typeof useUpdateRoutineFromTaskGroupMutation>;
export type UpdateRoutineFromTaskGroupMutationResult = Apollo.MutationResult<UpdateRoutineFromTaskGroupMutation>;
export type UpdateRoutineFromTaskGroupMutationOptions = Apollo.BaseMutationOptions<UpdateRoutineFromTaskGroupMutation, UpdateRoutineFromTaskGroupMutationVariables>;
export const MoveIncompleteTasksOfTaskGroupToDateDocument = gql`
    mutation MoveIncompleteTasksOfTaskGroupToDate($dailyPlanTaskGroupId: String!, $date: String!) {
  moveIncompleteTasksOfTaskGroupToDate(
    dailyPlanTaskGroupId: $dailyPlanTaskGroupId
    date: $date
  ) {
    success
    error
  }
}
    `;
export type MoveIncompleteTasksOfTaskGroupToDateMutationFn = Apollo.MutationFunction<MoveIncompleteTasksOfTaskGroupToDateMutation, MoveIncompleteTasksOfTaskGroupToDateMutationVariables>;

/**
 * __useMoveIncompleteTasksOfTaskGroupToDateMutation__
 *
 * To run a mutation, you first call `useMoveIncompleteTasksOfTaskGroupToDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveIncompleteTasksOfTaskGroupToDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveIncompleteTasksOfTaskGroupToDateMutation, { data, loading, error }] = useMoveIncompleteTasksOfTaskGroupToDateMutation({
 *   variables: {
 *      dailyPlanTaskGroupId: // value for 'dailyPlanTaskGroupId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useMoveIncompleteTasksOfTaskGroupToDateMutation(baseOptions?: Apollo.MutationHookOptions<MoveIncompleteTasksOfTaskGroupToDateMutation, MoveIncompleteTasksOfTaskGroupToDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveIncompleteTasksOfTaskGroupToDateMutation, MoveIncompleteTasksOfTaskGroupToDateMutationVariables>(MoveIncompleteTasksOfTaskGroupToDateDocument, options);
      }
export type MoveIncompleteTasksOfTaskGroupToDateMutationHookResult = ReturnType<typeof useMoveIncompleteTasksOfTaskGroupToDateMutation>;
export type MoveIncompleteTasksOfTaskGroupToDateMutationResult = Apollo.MutationResult<MoveIncompleteTasksOfTaskGroupToDateMutation>;
export type MoveIncompleteTasksOfTaskGroupToDateMutationOptions = Apollo.BaseMutationOptions<MoveIncompleteTasksOfTaskGroupToDateMutation, MoveIncompleteTasksOfTaskGroupToDateMutationVariables>;
export const SetUserTaskGroupProjectDocument = gql`
    mutation SetUserTaskGroupProject($dailyPlanTaskGroupId: String!, $projectId: String!) {
  setUserTaskGroupProject(
    dailyPlanTaskGroupId: $dailyPlanTaskGroupId
    projectId: $projectId
  ) {
    success
    error
  }
}
    `;
export type SetUserTaskGroupProjectMutationFn = Apollo.MutationFunction<SetUserTaskGroupProjectMutation, SetUserTaskGroupProjectMutationVariables>;

/**
 * __useSetUserTaskGroupProjectMutation__
 *
 * To run a mutation, you first call `useSetUserTaskGroupProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserTaskGroupProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserTaskGroupProjectMutation, { data, loading, error }] = useSetUserTaskGroupProjectMutation({
 *   variables: {
 *      dailyPlanTaskGroupId: // value for 'dailyPlanTaskGroupId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useSetUserTaskGroupProjectMutation(baseOptions?: Apollo.MutationHookOptions<SetUserTaskGroupProjectMutation, SetUserTaskGroupProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserTaskGroupProjectMutation, SetUserTaskGroupProjectMutationVariables>(SetUserTaskGroupProjectDocument, options);
      }
export type SetUserTaskGroupProjectMutationHookResult = ReturnType<typeof useSetUserTaskGroupProjectMutation>;
export type SetUserTaskGroupProjectMutationResult = Apollo.MutationResult<SetUserTaskGroupProjectMutation>;
export type SetUserTaskGroupProjectMutationOptions = Apollo.BaseMutationOptions<SetUserTaskGroupProjectMutation, SetUserTaskGroupProjectMutationVariables>;
export const UnsetUserTaskGroupProjectDocument = gql`
    mutation UnsetUserTaskGroupProject($dailyPlanTaskGroupId: String!) {
  unsetUserTaskGroupProject(dailyPlanTaskGroupId: $dailyPlanTaskGroupId) {
    success
    error
  }
}
    `;
export type UnsetUserTaskGroupProjectMutationFn = Apollo.MutationFunction<UnsetUserTaskGroupProjectMutation, UnsetUserTaskGroupProjectMutationVariables>;

/**
 * __useUnsetUserTaskGroupProjectMutation__
 *
 * To run a mutation, you first call `useUnsetUserTaskGroupProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsetUserTaskGroupProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsetUserTaskGroupProjectMutation, { data, loading, error }] = useUnsetUserTaskGroupProjectMutation({
 *   variables: {
 *      dailyPlanTaskGroupId: // value for 'dailyPlanTaskGroupId'
 *   },
 * });
 */
export function useUnsetUserTaskGroupProjectMutation(baseOptions?: Apollo.MutationHookOptions<UnsetUserTaskGroupProjectMutation, UnsetUserTaskGroupProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsetUserTaskGroupProjectMutation, UnsetUserTaskGroupProjectMutationVariables>(UnsetUserTaskGroupProjectDocument, options);
      }
export type UnsetUserTaskGroupProjectMutationHookResult = ReturnType<typeof useUnsetUserTaskGroupProjectMutation>;
export type UnsetUserTaskGroupProjectMutationResult = Apollo.MutationResult<UnsetUserTaskGroupProjectMutation>;
export type UnsetUserTaskGroupProjectMutationOptions = Apollo.BaseMutationOptions<UnsetUserTaskGroupProjectMutation, UnsetUserTaskGroupProjectMutationVariables>;
export const CreateUserProjectDocument = gql`
    mutation CreateUserProject($projectId: String!, $name: String!, $color: String!, $showOnScoreboard: Boolean!, $startDate: String!, $endDate: String!, $weeklyPaceTimeBlockCount: Int!, $weekStartsDayOfWeek: Int!, $roleId: String!) {
  createUserProject(
    projectId: $projectId
    name: $name
    color: $color
    showOnScoreboard: $showOnScoreboard
    startDate: $startDate
    endDate: $endDate
    weeklyPaceTimeBlockCount: $weeklyPaceTimeBlockCount
    weekStartsDayOfWeek: $weekStartsDayOfWeek
    roleId: $roleId
  ) {
    success
    error
  }
}
    `;
export type CreateUserProjectMutationFn = Apollo.MutationFunction<CreateUserProjectMutation, CreateUserProjectMutationVariables>;

/**
 * __useCreateUserProjectMutation__
 *
 * To run a mutation, you first call `useCreateUserProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserProjectMutation, { data, loading, error }] = useCreateUserProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      name: // value for 'name'
 *      color: // value for 'color'
 *      showOnScoreboard: // value for 'showOnScoreboard'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      weeklyPaceTimeBlockCount: // value for 'weeklyPaceTimeBlockCount'
 *      weekStartsDayOfWeek: // value for 'weekStartsDayOfWeek'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useCreateUserProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserProjectMutation, CreateUserProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserProjectMutation, CreateUserProjectMutationVariables>(CreateUserProjectDocument, options);
      }
export type CreateUserProjectMutationHookResult = ReturnType<typeof useCreateUserProjectMutation>;
export type CreateUserProjectMutationResult = Apollo.MutationResult<CreateUserProjectMutation>;
export type CreateUserProjectMutationOptions = Apollo.BaseMutationOptions<CreateUserProjectMutation, CreateUserProjectMutationVariables>;
export const UpdateUserProjectDocument = gql`
    mutation UpdateUserProject($projectId: String!, $name: String!, $color: String!, $showOnScoreboard: Boolean!, $startDate: String!, $endDate: String!, $weeklyPaceTimeBlockCount: Int!, $weekStartsDayOfWeek: Int!) {
  updateUserProject(
    projectId: $projectId
    name: $name
    color: $color
    showOnScoreboard: $showOnScoreboard
    startDate: $startDate
    endDate: $endDate
    weeklyPaceTimeBlockCount: $weeklyPaceTimeBlockCount
    weekStartsDayOfWeek: $weekStartsDayOfWeek
  ) {
    success
    error
  }
}
    `;
export type UpdateUserProjectMutationFn = Apollo.MutationFunction<UpdateUserProjectMutation, UpdateUserProjectMutationVariables>;

/**
 * __useUpdateUserProjectMutation__
 *
 * To run a mutation, you first call `useUpdateUserProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProjectMutation, { data, loading, error }] = useUpdateUserProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      name: // value for 'name'
 *      color: // value for 'color'
 *      showOnScoreboard: // value for 'showOnScoreboard'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      weeklyPaceTimeBlockCount: // value for 'weeklyPaceTimeBlockCount'
 *      weekStartsDayOfWeek: // value for 'weekStartsDayOfWeek'
 *   },
 * });
 */
export function useUpdateUserProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProjectMutation, UpdateUserProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProjectMutation, UpdateUserProjectMutationVariables>(UpdateUserProjectDocument, options);
      }
export type UpdateUserProjectMutationHookResult = ReturnType<typeof useUpdateUserProjectMutation>;
export type UpdateUserProjectMutationResult = Apollo.MutationResult<UpdateUserProjectMutation>;
export type UpdateUserProjectMutationOptions = Apollo.BaseMutationOptions<UpdateUserProjectMutation, UpdateUserProjectMutationVariables>;
export const GetProjectScoreboardDocument = gql`
    query GetProjectScoreboard($projectId: String!) {
  userProject(projectId: $projectId) {
    id
    dataForScoreboard {
      id
      weekEndDate
      completedTimeBlockCount
    }
  }
}
    `;

/**
 * __useGetProjectScoreboardQuery__
 *
 * To run a query within a React component, call `useGetProjectScoreboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectScoreboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectScoreboardQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectScoreboardQuery(baseOptions: Apollo.QueryHookOptions<GetProjectScoreboardQuery, GetProjectScoreboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectScoreboardQuery, GetProjectScoreboardQueryVariables>(GetProjectScoreboardDocument, options);
      }
export function useGetProjectScoreboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectScoreboardQuery, GetProjectScoreboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectScoreboardQuery, GetProjectScoreboardQueryVariables>(GetProjectScoreboardDocument, options);
        }
export type GetProjectScoreboardQueryHookResult = ReturnType<typeof useGetProjectScoreboardQuery>;
export type GetProjectScoreboardLazyQueryHookResult = ReturnType<typeof useGetProjectScoreboardLazyQuery>;
export type GetProjectScoreboardQueryResult = Apollo.QueryResult<GetProjectScoreboardQuery, GetProjectScoreboardQueryVariables>;
export const RoutinesDocument = gql`
    query Routines {
  userRoutines {
    id
    name
    timeBlockCount
    archived
    isGrayTime
    daysOfTheWeek
    project {
      id
      name
    }
    role {
      id
    }
    tasks {
      id
      name
      order
    }
  }
  userAccount {
    id
    roles {
      id
      name
      color
      order
    }
  }
}
    `;

/**
 * __useRoutinesQuery__
 *
 * To run a query within a React component, call `useRoutinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoutinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoutinesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoutinesQuery(baseOptions?: Apollo.QueryHookOptions<RoutinesQuery, RoutinesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoutinesQuery, RoutinesQueryVariables>(RoutinesDocument, options);
      }
export function useRoutinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoutinesQuery, RoutinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoutinesQuery, RoutinesQueryVariables>(RoutinesDocument, options);
        }
export type RoutinesQueryHookResult = ReturnType<typeof useRoutinesQuery>;
export type RoutinesLazyQueryHookResult = ReturnType<typeof useRoutinesLazyQuery>;
export type RoutinesQueryResult = Apollo.QueryResult<RoutinesQuery, RoutinesQueryVariables>;
export const AddRoutineToPlanDocument = gql`
    mutation AddRoutineToPlan($routineId: String!, $dailyPlanId: String!, $date: String!, $taskGroupId: String!, $taskGroupOrder: Float!) {
  addRoutineToPlan(
    routineId: $routineId
    dailyPlanId: $dailyPlanId
    date: $date
    taskGroupId: $taskGroupId
    taskGroupOrder: $taskGroupOrder
  ) {
    success
    error
    idsInserted
  }
}
    `;
export type AddRoutineToPlanMutationFn = Apollo.MutationFunction<AddRoutineToPlanMutation, AddRoutineToPlanMutationVariables>;

/**
 * __useAddRoutineToPlanMutation__
 *
 * To run a mutation, you first call `useAddRoutineToPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRoutineToPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRoutineToPlanMutation, { data, loading, error }] = useAddRoutineToPlanMutation({
 *   variables: {
 *      routineId: // value for 'routineId'
 *      dailyPlanId: // value for 'dailyPlanId'
 *      date: // value for 'date'
 *      taskGroupId: // value for 'taskGroupId'
 *      taskGroupOrder: // value for 'taskGroupOrder'
 *   },
 * });
 */
export function useAddRoutineToPlanMutation(baseOptions?: Apollo.MutationHookOptions<AddRoutineToPlanMutation, AddRoutineToPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRoutineToPlanMutation, AddRoutineToPlanMutationVariables>(AddRoutineToPlanDocument, options);
      }
export type AddRoutineToPlanMutationHookResult = ReturnType<typeof useAddRoutineToPlanMutation>;
export type AddRoutineToPlanMutationResult = Apollo.MutationResult<AddRoutineToPlanMutation>;
export type AddRoutineToPlanMutationOptions = Apollo.BaseMutationOptions<AddRoutineToPlanMutation, AddRoutineToPlanMutationVariables>;
export const ArchiveUserRoutineDocument = gql`
    mutation ArchiveUserRoutine($routineId: String!) {
  archiveUserRoutine(routineId: $routineId) {
    success
    error
  }
}
    `;
export type ArchiveUserRoutineMutationFn = Apollo.MutationFunction<ArchiveUserRoutineMutation, ArchiveUserRoutineMutationVariables>;

/**
 * __useArchiveUserRoutineMutation__
 *
 * To run a mutation, you first call `useArchiveUserRoutineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveUserRoutineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveUserRoutineMutation, { data, loading, error }] = useArchiveUserRoutineMutation({
 *   variables: {
 *      routineId: // value for 'routineId'
 *   },
 * });
 */
export function useArchiveUserRoutineMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveUserRoutineMutation, ArchiveUserRoutineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveUserRoutineMutation, ArchiveUserRoutineMutationVariables>(ArchiveUserRoutineDocument, options);
      }
export type ArchiveUserRoutineMutationHookResult = ReturnType<typeof useArchiveUserRoutineMutation>;
export type ArchiveUserRoutineMutationResult = Apollo.MutationResult<ArchiveUserRoutineMutation>;
export type ArchiveUserRoutineMutationOptions = Apollo.BaseMutationOptions<ArchiveUserRoutineMutation, ArchiveUserRoutineMutationVariables>;
export const SetUserRoutineDaysOfTheWeekDocument = gql`
    mutation SetUserRoutineDaysOfTheWeek($routineId: String!, $daysOfTheWeek: Int!) {
  setUserRoutineDaysOfTheWeek(
    routineId: $routineId
    daysOfTheWeek: $daysOfTheWeek
  ) {
    success
    error
  }
}
    `;
export type SetUserRoutineDaysOfTheWeekMutationFn = Apollo.MutationFunction<SetUserRoutineDaysOfTheWeekMutation, SetUserRoutineDaysOfTheWeekMutationVariables>;

/**
 * __useSetUserRoutineDaysOfTheWeekMutation__
 *
 * To run a mutation, you first call `useSetUserRoutineDaysOfTheWeekMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserRoutineDaysOfTheWeekMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserRoutineDaysOfTheWeekMutation, { data, loading, error }] = useSetUserRoutineDaysOfTheWeekMutation({
 *   variables: {
 *      routineId: // value for 'routineId'
 *      daysOfTheWeek: // value for 'daysOfTheWeek'
 *   },
 * });
 */
export function useSetUserRoutineDaysOfTheWeekMutation(baseOptions?: Apollo.MutationHookOptions<SetUserRoutineDaysOfTheWeekMutation, SetUserRoutineDaysOfTheWeekMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserRoutineDaysOfTheWeekMutation, SetUserRoutineDaysOfTheWeekMutationVariables>(SetUserRoutineDaysOfTheWeekDocument, options);
      }
export type SetUserRoutineDaysOfTheWeekMutationHookResult = ReturnType<typeof useSetUserRoutineDaysOfTheWeekMutation>;
export type SetUserRoutineDaysOfTheWeekMutationResult = Apollo.MutationResult<SetUserRoutineDaysOfTheWeekMutation>;
export type SetUserRoutineDaysOfTheWeekMutationOptions = Apollo.BaseMutationOptions<SetUserRoutineDaysOfTheWeekMutation, SetUserRoutineDaysOfTheWeekMutationVariables>;
export const RoutinesPageDocument = gql`
    query RoutinesPage {
  userRoutines {
    id
    name
    role {
      id
    }
    timeBlockCount
    tasks {
      id
      name
      order
    }
  }
  userAccount {
    id
    roles {
      id
      name
      color
      order
    }
  }
}
    `;

/**
 * __useRoutinesPageQuery__
 *
 * To run a query within a React component, call `useRoutinesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoutinesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoutinesPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoutinesPageQuery(baseOptions?: Apollo.QueryHookOptions<RoutinesPageQuery, RoutinesPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoutinesPageQuery, RoutinesPageQueryVariables>(RoutinesPageDocument, options);
      }
export function useRoutinesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoutinesPageQuery, RoutinesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoutinesPageQuery, RoutinesPageQueryVariables>(RoutinesPageDocument, options);
        }
export type RoutinesPageQueryHookResult = ReturnType<typeof useRoutinesPageQuery>;
export type RoutinesPageLazyQueryHookResult = ReturnType<typeof useRoutinesPageLazyQuery>;
export type RoutinesPageQueryResult = Apollo.QueryResult<RoutinesPageQuery, RoutinesPageQueryVariables>;
export const SettingsDocument = gql`
    query Settings {
  userAccount {
    id
    grayIsDefault
    defaultStartTimeBlockNumber
    defaultEndTimeBlockNumber
  }
}
    `;

/**
 * __useSettingsQuery__
 *
 * To run a query within a React component, call `useSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsQuery(baseOptions?: Apollo.QueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
      }
export function useSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
        }
export type SettingsQueryHookResult = ReturnType<typeof useSettingsQuery>;
export type SettingsLazyQueryHookResult = ReturnType<typeof useSettingsLazyQuery>;
export type SettingsQueryResult = Apollo.QueryResult<SettingsQuery, SettingsQueryVariables>;
export const SetUserSettingsDocument = gql`
    mutation SetUserSettings($grayIsDefault: Boolean!, $defaultStartTimeBlockNumber: Int!, $defaultEndTimeBlockNumber: Int!) {
  setUserSettings(
    grayIsDefault: $grayIsDefault
    defaultStartTimeBlockNumber: $defaultStartTimeBlockNumber
    defaultEndTimeBlockNumber: $defaultEndTimeBlockNumber
  ) {
    success
    error
  }
}
    `;
export type SetUserSettingsMutationFn = Apollo.MutationFunction<SetUserSettingsMutation, SetUserSettingsMutationVariables>;

/**
 * __useSetUserSettingsMutation__
 *
 * To run a mutation, you first call `useSetUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserSettingsMutation, { data, loading, error }] = useSetUserSettingsMutation({
 *   variables: {
 *      grayIsDefault: // value for 'grayIsDefault'
 *      defaultStartTimeBlockNumber: // value for 'defaultStartTimeBlockNumber'
 *      defaultEndTimeBlockNumber: // value for 'defaultEndTimeBlockNumber'
 *   },
 * });
 */
export function useSetUserSettingsMutation(baseOptions?: Apollo.MutationHookOptions<SetUserSettingsMutation, SetUserSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserSettingsMutation, SetUserSettingsMutationVariables>(SetUserSettingsDocument, options);
      }
export type SetUserSettingsMutationHookResult = ReturnType<typeof useSetUserSettingsMutation>;
export type SetUserSettingsMutationResult = Apollo.MutationResult<SetUserSettingsMutation>;
export type SetUserSettingsMutationOptions = Apollo.BaseMutationOptions<SetUserSettingsMutation, SetUserSettingsMutationVariables>;
export const UserTasksDocument = gql`
    query UserTasks {
  userTasks {
    id
    name
    completed
    order
  }
}
    `;

/**
 * __useUserTasksQuery__
 *
 * To run a query within a React component, call `useUserTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserTasksQuery(baseOptions?: Apollo.QueryHookOptions<UserTasksQuery, UserTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTasksQuery, UserTasksQueryVariables>(UserTasksDocument, options);
      }
export function useUserTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTasksQuery, UserTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTasksQuery, UserTasksQueryVariables>(UserTasksDocument, options);
        }
export type UserTasksQueryHookResult = ReturnType<typeof useUserTasksQuery>;
export type UserTasksLazyQueryHookResult = ReturnType<typeof useUserTasksLazyQuery>;
export type UserTasksQueryResult = Apollo.QueryResult<UserTasksQuery, UserTasksQueryVariables>;
export const SetUserTaskCompletedDocument = gql`
    mutation SetUserTaskCompleted($taskId: String!, $completed: Boolean!) {
  setUserTaskCompleted(taskId: $taskId, completed: $completed) {
    success
    error
  }
}
    `;
export type SetUserTaskCompletedMutationFn = Apollo.MutationFunction<SetUserTaskCompletedMutation, SetUserTaskCompletedMutationVariables>;

/**
 * __useSetUserTaskCompletedMutation__
 *
 * To run a mutation, you first call `useSetUserTaskCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserTaskCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserTaskCompletedMutation, { data, loading, error }] = useSetUserTaskCompletedMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      completed: // value for 'completed'
 *   },
 * });
 */
export function useSetUserTaskCompletedMutation(baseOptions?: Apollo.MutationHookOptions<SetUserTaskCompletedMutation, SetUserTaskCompletedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserTaskCompletedMutation, SetUserTaskCompletedMutationVariables>(SetUserTaskCompletedDocument, options);
      }
export type SetUserTaskCompletedMutationHookResult = ReturnType<typeof useSetUserTaskCompletedMutation>;
export type SetUserTaskCompletedMutationResult = Apollo.MutationResult<SetUserTaskCompletedMutation>;
export type SetUserTaskCompletedMutationOptions = Apollo.BaseMutationOptions<SetUserTaskCompletedMutation, SetUserTaskCompletedMutationVariables>;
export const UnplanTaskDocument = gql`
    mutation UnplanTask($taskId: String!) {
  unplanTask(taskId: $taskId) {
    success
    error
  }
}
    `;
export type UnplanTaskMutationFn = Apollo.MutationFunction<UnplanTaskMutation, UnplanTaskMutationVariables>;

/**
 * __useUnplanTaskMutation__
 *
 * To run a mutation, you first call `useUnplanTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnplanTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unplanTaskMutation, { data, loading, error }] = useUnplanTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useUnplanTaskMutation(baseOptions?: Apollo.MutationHookOptions<UnplanTaskMutation, UnplanTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnplanTaskMutation, UnplanTaskMutationVariables>(UnplanTaskDocument, options);
      }
export type UnplanTaskMutationHookResult = ReturnType<typeof useUnplanTaskMutation>;
export type UnplanTaskMutationResult = Apollo.MutationResult<UnplanTaskMutation>;
export type UnplanTaskMutationOptions = Apollo.BaseMutationOptions<UnplanTaskMutation, UnplanTaskMutationVariables>;
export const AddUnplannedTaskDocument = gql`
    mutation AddUnplannedTask($taskId: String!, $roleId: String!, $name: String!, $order: Float!) {
  addUnplannedTask(taskId: $taskId, roleId: $roleId, name: $name, order: $order) {
    success
    error
  }
}
    `;
export type AddUnplannedTaskMutationFn = Apollo.MutationFunction<AddUnplannedTaskMutation, AddUnplannedTaskMutationVariables>;

/**
 * __useAddUnplannedTaskMutation__
 *
 * To run a mutation, you first call `useAddUnplannedTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUnplannedTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUnplannedTaskMutation, { data, loading, error }] = useAddUnplannedTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      roleId: // value for 'roleId'
 *      name: // value for 'name'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAddUnplannedTaskMutation(baseOptions?: Apollo.MutationHookOptions<AddUnplannedTaskMutation, AddUnplannedTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUnplannedTaskMutation, AddUnplannedTaskMutationVariables>(AddUnplannedTaskDocument, options);
      }
export type AddUnplannedTaskMutationHookResult = ReturnType<typeof useAddUnplannedTaskMutation>;
export type AddUnplannedTaskMutationResult = Apollo.MutationResult<AddUnplannedTaskMutation>;
export type AddUnplannedTaskMutationOptions = Apollo.BaseMutationOptions<AddUnplannedTaskMutation, AddUnplannedTaskMutationVariables>;
export const DeleteUnplannedTaskDocument = gql`
    mutation DeleteUnplannedTask($taskId: String!) {
  deleteUnplannedTask(taskId: $taskId) {
    success
    error
  }
}
    `;
export type DeleteUnplannedTaskMutationFn = Apollo.MutationFunction<DeleteUnplannedTaskMutation, DeleteUnplannedTaskMutationVariables>;

/**
 * __useDeleteUnplannedTaskMutation__
 *
 * To run a mutation, you first call `useDeleteUnplannedTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUnplannedTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUnplannedTaskMutation, { data, loading, error }] = useDeleteUnplannedTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useDeleteUnplannedTaskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUnplannedTaskMutation, DeleteUnplannedTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUnplannedTaskMutation, DeleteUnplannedTaskMutationVariables>(DeleteUnplannedTaskDocument, options);
      }
export type DeleteUnplannedTaskMutationHookResult = ReturnType<typeof useDeleteUnplannedTaskMutation>;
export type DeleteUnplannedTaskMutationResult = Apollo.MutationResult<DeleteUnplannedTaskMutation>;
export type DeleteUnplannedTaskMutationOptions = Apollo.BaseMutationOptions<DeleteUnplannedTaskMutation, DeleteUnplannedTaskMutationVariables>;
export const SetUserTaskOrderDocument = gql`
    mutation SetUserTaskOrder($taskId: String!, $order: Float!) {
  setUserTaskOrder(taskId: $taskId, order: $order) {
    success
    error
  }
}
    `;
export type SetUserTaskOrderMutationFn = Apollo.MutationFunction<SetUserTaskOrderMutation, SetUserTaskOrderMutationVariables>;

/**
 * __useSetUserTaskOrderMutation__
 *
 * To run a mutation, you first call `useSetUserTaskOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserTaskOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserTaskOrderMutation, { data, loading, error }] = useSetUserTaskOrderMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSetUserTaskOrderMutation(baseOptions?: Apollo.MutationHookOptions<SetUserTaskOrderMutation, SetUserTaskOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserTaskOrderMutation, SetUserTaskOrderMutationVariables>(SetUserTaskOrderDocument, options);
      }
export type SetUserTaskOrderMutationHookResult = ReturnType<typeof useSetUserTaskOrderMutation>;
export type SetUserTaskOrderMutationResult = Apollo.MutationResult<SetUserTaskOrderMutation>;
export type SetUserTaskOrderMutationOptions = Apollo.BaseMutationOptions<SetUserTaskOrderMutation, SetUserTaskOrderMutationVariables>;
export const SetUserTaskNameDocument = gql`
    mutation SetUserTaskName($taskId: String!, $name: String!) {
  setUserTaskName(taskId: $taskId, name: $name) {
    success
    error
  }
}
    `;
export type SetUserTaskNameMutationFn = Apollo.MutationFunction<SetUserTaskNameMutation, SetUserTaskNameMutationVariables>;

/**
 * __useSetUserTaskNameMutation__
 *
 * To run a mutation, you first call `useSetUserTaskNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserTaskNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserTaskNameMutation, { data, loading, error }] = useSetUserTaskNameMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSetUserTaskNameMutation(baseOptions?: Apollo.MutationHookOptions<SetUserTaskNameMutation, SetUserTaskNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserTaskNameMutation, SetUserTaskNameMutationVariables>(SetUserTaskNameDocument, options);
      }
export type SetUserTaskNameMutationHookResult = ReturnType<typeof useSetUserTaskNameMutation>;
export type SetUserTaskNameMutationResult = Apollo.MutationResult<SetUserTaskNameMutation>;
export type SetUserTaskNameMutationOptions = Apollo.BaseMutationOptions<SetUserTaskNameMutation, SetUserTaskNameMutationVariables>;
export const SetUserTaskRoleDocument = gql`
    mutation SetUserTaskRole($taskId: String!, $roleId: String!) {
  setUserTaskRole(taskId: $taskId, roleId: $roleId) {
    success
    error
  }
}
    `;
export type SetUserTaskRoleMutationFn = Apollo.MutationFunction<SetUserTaskRoleMutation, SetUserTaskRoleMutationVariables>;

/**
 * __useSetUserTaskRoleMutation__
 *
 * To run a mutation, you first call `useSetUserTaskRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserTaskRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserTaskRoleMutation, { data, loading, error }] = useSetUserTaskRoleMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useSetUserTaskRoleMutation(baseOptions?: Apollo.MutationHookOptions<SetUserTaskRoleMutation, SetUserTaskRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserTaskRoleMutation, SetUserTaskRoleMutationVariables>(SetUserTaskRoleDocument, options);
      }
export type SetUserTaskRoleMutationHookResult = ReturnType<typeof useSetUserTaskRoleMutation>;
export type SetUserTaskRoleMutationResult = Apollo.MutationResult<SetUserTaskRoleMutation>;
export type SetUserTaskRoleMutationOptions = Apollo.BaseMutationOptions<SetUserTaskRoleMutation, SetUserTaskRoleMutationVariables>;
export const DeletePlannedTaskDocument = gql`
    mutation DeletePlannedTask($taskId: String!) {
  deletePlannedTask(taskId: $taskId) {
    success
    error
  }
}
    `;
export type DeletePlannedTaskMutationFn = Apollo.MutationFunction<DeletePlannedTaskMutation, DeletePlannedTaskMutationVariables>;

/**
 * __useDeletePlannedTaskMutation__
 *
 * To run a mutation, you first call `useDeletePlannedTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlannedTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlannedTaskMutation, { data, loading, error }] = useDeletePlannedTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useDeletePlannedTaskMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlannedTaskMutation, DeletePlannedTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlannedTaskMutation, DeletePlannedTaskMutationVariables>(DeletePlannedTaskDocument, options);
      }
export type DeletePlannedTaskMutationHookResult = ReturnType<typeof useDeletePlannedTaskMutation>;
export type DeletePlannedTaskMutationResult = Apollo.MutationResult<DeletePlannedTaskMutation>;
export type DeletePlannedTaskMutationOptions = Apollo.BaseMutationOptions<DeletePlannedTaskMutation, DeletePlannedTaskMutationVariables>;
export const TodayPageDocument = gql`
    query TodayPage($date: String!) {
  userDailyPlanForDate(date: $date) {
    id
    maxTimeBlockCount
    startTimeBlockNumber
    endTimeBlockNumber
    taskGroups {
      id
      name
      role {
        id
      }
      timeBlockCount
      completedTimeBlockCount
      startTimeBlockNumber
      order
      collapsed
      isGrayTime
      tasks {
        id
        name
        completed
        order
      }
      routine {
        id
        name
      }
      project {
        id
        name
      }
    }
    plannedBlocks {
      id
      type
      blockNumber
      role {
        id
      }
    }
  }
  weekPlanTimeBlockCounts(date: $date) {
    planId
    date
    roleId
    capacity
    completedTimeBlockCount
    grayCompletedTimeBlockCount
  }
  userAccount {
    id
    roles {
      id
      name
      color
      order
    }
    projects {
      id
      name
      role {
        id
      }
      color
      showOnScoreboard
      startDate
      endDate
      weeklyPaceTimeBlockCount
      weekStartsDayOfWeek
      dataForScoreboard {
        id
        weekEndDate
        completedTimeBlockCount
      }
    }
  }
}
    `;

/**
 * __useTodayPageQuery__
 *
 * To run a query within a React component, call `useTodayPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodayPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodayPageQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useTodayPageQuery(baseOptions: Apollo.QueryHookOptions<TodayPageQuery, TodayPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TodayPageQuery, TodayPageQueryVariables>(TodayPageDocument, options);
      }
export function useTodayPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TodayPageQuery, TodayPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TodayPageQuery, TodayPageQueryVariables>(TodayPageDocument, options);
        }
export type TodayPageQueryHookResult = ReturnType<typeof useTodayPageQuery>;
export type TodayPageLazyQueryHookResult = ReturnType<typeof useTodayPageLazyQuery>;
export type TodayPageQueryResult = Apollo.QueryResult<TodayPageQuery, TodayPageQueryVariables>;
export const UserAccountDocument = gql`
    query UserAccount {
  userAccount {
    id
    displayName
  }
}
    `;

/**
 * __useUserAccountQuery__
 *
 * To run a query within a React component, call `useUserAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserAccountQuery(baseOptions?: Apollo.QueryHookOptions<UserAccountQuery, UserAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserAccountQuery, UserAccountQueryVariables>(UserAccountDocument, options);
      }
export function useUserAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserAccountQuery, UserAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserAccountQuery, UserAccountQueryVariables>(UserAccountDocument, options);
        }
export type UserAccountQueryHookResult = ReturnType<typeof useUserAccountQuery>;
export type UserAccountLazyQueryHookResult = ReturnType<typeof useUserAccountLazyQuery>;
export type UserAccountQueryResult = Apollo.QueryResult<UserAccountQuery, UserAccountQueryVariables>;
export const UserForInviteDocument = gql`
    query UserForInvite($inviteCode: String!) {
  userForInvite(inviteCode: $inviteCode) {
    id
    email
    displayName
  }
}
    `;

/**
 * __useUserForInviteQuery__
 *
 * To run a query within a React component, call `useUserForInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserForInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserForInviteQuery({
 *   variables: {
 *      inviteCode: // value for 'inviteCode'
 *   },
 * });
 */
export function useUserForInviteQuery(baseOptions: Apollo.QueryHookOptions<UserForInviteQuery, UserForInviteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserForInviteQuery, UserForInviteQueryVariables>(UserForInviteDocument, options);
      }
export function useUserForInviteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserForInviteQuery, UserForInviteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserForInviteQuery, UserForInviteQueryVariables>(UserForInviteDocument, options);
        }
export type UserForInviteQueryHookResult = ReturnType<typeof useUserForInviteQuery>;
export type UserForInviteLazyQueryHookResult = ReturnType<typeof useUserForInviteLazyQuery>;
export type UserForInviteQueryResult = Apollo.QueryResult<UserForInviteQuery, UserForInviteQueryVariables>;
export const InvitationsDocument = gql`
    query Invitations {
  invitedUsers {
    id
    email
    displayName
    createdDate
  }
  inviteRequestedUsers {
    id
    email
    displayName
    createdDate
  }
}
    `;

/**
 * __useInvitationsQuery__
 *
 * To run a query within a React component, call `useInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvitationsQuery(baseOptions?: Apollo.QueryHookOptions<InvitationsQuery, InvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvitationsQuery, InvitationsQueryVariables>(InvitationsDocument, options);
      }
export function useInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvitationsQuery, InvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvitationsQuery, InvitationsQueryVariables>(InvitationsDocument, options);
        }
export type InvitationsQueryHookResult = ReturnType<typeof useInvitationsQuery>;
export type InvitationsLazyQueryHookResult = ReturnType<typeof useInvitationsLazyQuery>;
export type InvitationsQueryResult = Apollo.QueryResult<InvitationsQuery, InvitationsQueryVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    accessToken
    isSiteAdmin
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
  changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
    accessToken
    isSiteAdmin
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const InviteSiteUserDocument = gql`
    mutation InviteSiteUser($email: String!, $displayName: String!, $inviteCode: String!) {
  inviteSiteUser(
    email: $email
    displayName: $displayName
    inviteCode: $inviteCode
  ) {
    success
    error
  }
}
    `;
export type InviteSiteUserMutationFn = Apollo.MutationFunction<InviteSiteUserMutation, InviteSiteUserMutationVariables>;

/**
 * __useInviteSiteUserMutation__
 *
 * To run a mutation, you first call `useInviteSiteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteSiteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteSiteUserMutation, { data, loading, error }] = useInviteSiteUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      displayName: // value for 'displayName'
 *      inviteCode: // value for 'inviteCode'
 *   },
 * });
 */
export function useInviteSiteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteSiteUserMutation, InviteSiteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteSiteUserMutation, InviteSiteUserMutationVariables>(InviteSiteUserDocument, options);
      }
export type InviteSiteUserMutationHookResult = ReturnType<typeof useInviteSiteUserMutation>;
export type InviteSiteUserMutationResult = Apollo.MutationResult<InviteSiteUserMutation>;
export type InviteSiteUserMutationOptions = Apollo.BaseMutationOptions<InviteSiteUserMutation, InviteSiteUserMutationVariables>;
export const InviteRequestedSiteUserDocument = gql`
    mutation InviteRequestedSiteUser($userId: String!) {
  inviteRequestedSiteUser(userId: $userId) {
    success
    error
  }
}
    `;
export type InviteRequestedSiteUserMutationFn = Apollo.MutationFunction<InviteRequestedSiteUserMutation, InviteRequestedSiteUserMutationVariables>;

/**
 * __useInviteRequestedSiteUserMutation__
 *
 * To run a mutation, you first call `useInviteRequestedSiteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteRequestedSiteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteRequestedSiteUserMutation, { data, loading, error }] = useInviteRequestedSiteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useInviteRequestedSiteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteRequestedSiteUserMutation, InviteRequestedSiteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteRequestedSiteUserMutation, InviteRequestedSiteUserMutationVariables>(InviteRequestedSiteUserDocument, options);
      }
export type InviteRequestedSiteUserMutationHookResult = ReturnType<typeof useInviteRequestedSiteUserMutation>;
export type InviteRequestedSiteUserMutationResult = Apollo.MutationResult<InviteRequestedSiteUserMutation>;
export type InviteRequestedSiteUserMutationOptions = Apollo.BaseMutationOptions<InviteRequestedSiteUserMutation, InviteRequestedSiteUserMutationVariables>;
export const RequestInviteForSiteUserDocument = gql`
    mutation RequestInviteForSiteUser($email: String!, $displayName: String!) {
  requestInviteForSiteUser(email: $email, displayName: $displayName) {
    success
    error
  }
}
    `;
export type RequestInviteForSiteUserMutationFn = Apollo.MutationFunction<RequestInviteForSiteUserMutation, RequestInviteForSiteUserMutationVariables>;

/**
 * __useRequestInviteForSiteUserMutation__
 *
 * To run a mutation, you first call `useRequestInviteForSiteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestInviteForSiteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestInviteForSiteUserMutation, { data, loading, error }] = useRequestInviteForSiteUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      displayName: // value for 'displayName'
 *   },
 * });
 */
export function useRequestInviteForSiteUserMutation(baseOptions?: Apollo.MutationHookOptions<RequestInviteForSiteUserMutation, RequestInviteForSiteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestInviteForSiteUserMutation, RequestInviteForSiteUserMutationVariables>(RequestInviteForSiteUserDocument, options);
      }
export type RequestInviteForSiteUserMutationHookResult = ReturnType<typeof useRequestInviteForSiteUserMutation>;
export type RequestInviteForSiteUserMutationResult = Apollo.MutationResult<RequestInviteForSiteUserMutation>;
export type RequestInviteForSiteUserMutationOptions = Apollo.BaseMutationOptions<RequestInviteForSiteUserMutation, RequestInviteForSiteUserMutationVariables>;
export const RegisterWithInvitationDocument = gql`
    mutation RegisterWithInvitation($inviteCode: String!, $password: String!) {
  registerWithInvitation(inviteCode: $inviteCode, password: $password) {
    accessToken
    isSiteAdmin
  }
}
    `;
export type RegisterWithInvitationMutationFn = Apollo.MutationFunction<RegisterWithInvitationMutation, RegisterWithInvitationMutationVariables>;

/**
 * __useRegisterWithInvitationMutation__
 *
 * To run a mutation, you first call `useRegisterWithInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterWithInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerWithInvitationMutation, { data, loading, error }] = useRegisterWithInvitationMutation({
 *   variables: {
 *      inviteCode: // value for 'inviteCode'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useRegisterWithInvitationMutation(baseOptions?: Apollo.MutationHookOptions<RegisterWithInvitationMutation, RegisterWithInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterWithInvitationMutation, RegisterWithInvitationMutationVariables>(RegisterWithInvitationDocument, options);
      }
export type RegisterWithInvitationMutationHookResult = ReturnType<typeof useRegisterWithInvitationMutation>;
export type RegisterWithInvitationMutationResult = Apollo.MutationResult<RegisterWithInvitationMutation>;
export type RegisterWithInvitationMutationOptions = Apollo.BaseMutationOptions<RegisterWithInvitationMutation, RegisterWithInvitationMutationVariables>;
export const ResendInvitationDocument = gql`
    mutation ResendInvitation($userId: String!) {
  resendInvitation(userId: $userId) {
    success
    error
  }
}
    `;
export type ResendInvitationMutationFn = Apollo.MutationFunction<ResendInvitationMutation, ResendInvitationMutationVariables>;

/**
 * __useResendInvitationMutation__
 *
 * To run a mutation, you first call `useResendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationMutation, { data, loading, error }] = useResendInvitationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResendInvitationMutation(baseOptions?: Apollo.MutationHookOptions<ResendInvitationMutation, ResendInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendInvitationMutation, ResendInvitationMutationVariables>(ResendInvitationDocument, options);
      }
export type ResendInvitationMutationHookResult = ReturnType<typeof useResendInvitationMutation>;
export type ResendInvitationMutationResult = Apollo.MutationResult<ResendInvitationMutation>;
export type ResendInvitationMutationOptions = Apollo.BaseMutationOptions<ResendInvitationMutation, ResendInvitationMutationVariables>;