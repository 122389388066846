import React from "react";
import { Box, Button, Stack, Tooltip } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import { RoleFilter } from "./roleFilter";
import { PlanRole } from "../../../util/modelTypes";
import { Dictionary } from "@reduxjs/toolkit";
import { RoleViewSelector } from "./roleViewSelector";
import { RoleViewCompletedSelector } from "./roleViewCompletedSelector";
import { PlanCollapser } from "./planCollapser";
import { isSiteAdmin } from "../../../util/auth";
import { ShowRoutinesButton } from "./showRoutinesButton";
import { AddTimeCard } from "./addTimeCard";

export const planTasksToolbarButtonDefaultStyle = {
  color: "#666",
  borderColor: "#CCC",
  ":hover": { backgroundColor: "#ddd", borderColor: "#333" },
};

interface Props {
  dailyPlanId: string;
  rolesList: PlanRole[];
  roles: Dictionary<PlanRole>;
  selectedRoles: Dictionary<PlanRole>;
  roleColor: string;
  roleView: string;
  setRoleView: (v: string) => void;
  roleViewCompleted: string;
  setRoleViewCompleted: (v: string) => void;
  toggleRoleIdSelectedInFilter: (roleId: string) => void;
  onAddTimeCardToPlan: (name: string, roleId: string) => void;
  defaultRoleIdForAddTask: string;
  setDefaultRoleIdForAddTask: (roleId: string) => void;
  setAllTaskgroupsCollapsed: (collapsed: boolean) => void;
  verifyServerMatchesClient: () => void;
  showRoutines: boolean;
  numberOfUnaddedRoutines: number;
  toggleShowRoutines: () => void;
}

export const PlanTasksToolbar: React.FC<Props> = ({
  dailyPlanId,
  rolesList,
  roles,
  selectedRoles,
  roleColor,
  roleView,
  setRoleView,
  roleViewCompleted,
  setRoleViewCompleted,
  toggleRoleIdSelectedInFilter,
  onAddTimeCardToPlan,
  defaultRoleIdForAddTask,
  setDefaultRoleIdForAddTask,
  setAllTaskgroupsCollapsed,
  verifyServerMatchesClient,
  showRoutines,
  numberOfUnaddedRoutines,
  toggleShowRoutines,
}) => {
  return (
    <Stack direction="row" spacing={1} sx={{ my: 1 }}>
      <RoleFilter
        rolesList={rolesList}
        roles={roles}
        selectedRoles={selectedRoles}
        toggleSelectRoleId={toggleRoleIdSelectedInFilter}
      />
      <RoleViewSelector roleView={roleView} setRoleView={setRoleView} />
      <RoleViewCompletedSelector roleViewCompleted={roleViewCompleted} setRoleViewCompleted={setRoleViewCompleted} />
      <PlanCollapser setAllTaskgroupsCollapsed={setAllTaskgroupsCollapsed} />
      {isSiteAdmin() && dailyPlanId !== "" && (
        <Tooltip title="Verify server matches client">
          <Button sx={{ ...planTasksToolbarButtonDefaultStyle }} variant="outlined" onClick={verifyServerMatchesClient}>
            <VerifiedIcon />
          </Button>
        </Tooltip>
      )}
      <Box sx={{ flexGrow: 1 }}></Box>
      <AddTimeCard
        roleColor={roleColor}
        rolesList={rolesList}
        onAddTimeCardToPlan={onAddTimeCardToPlan}
        defaultRoleIdForAddTask={defaultRoleIdForAddTask}
        setDefaultRoleIdForAddTask={setDefaultRoleIdForAddTask}
      />
      <ShowRoutinesButton
        showRoutines={showRoutines}
        numberOfUnaddedRoutines={numberOfUnaddedRoutines}
        toggleShowRoutines={toggleShowRoutines}
      />
    </Stack>
  );
};
